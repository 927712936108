<template>
  <div class="page-container">
    <section-title>注意事项</section-title>
    <notice :content="notice | decodeHTML"></notice>
    <div :is="type"></div>
  </div>
</template>

<script>
import brokenScreen from "@/views/ClaimSettlement/apply/brokenScreen";
import iphone from "@/views/ClaimSettlement/apply/iphone";
import upServe from "@/views/ClaimSettlement/apply/upServe";
import android from "@/views/ClaimSettlement/apply/android";
import androidYearChange from "@/views/ClaimSettlement/apply/androidYearChange";
import iphoneYearChange from "@/views/ClaimSettlement/apply/iphoneYearChange";
import smartChoice from "@/views/ClaimSettlement/apply/smartChoice";
import { article } from "@/api";
import huawei from "@/views/ClaimSettlement/apply/huawei";
import iphoneSmart from "@/views/ClaimSettlement/apply/iphoneSmart";
import yanbaofuwu from "@/views/ClaimSettlement/apply/yanbaofuwu";
import dianchifuwu from "@/views/ClaimSettlement/apply/dianchifuwu";
import houdun from "@/views/ClaimSettlement/apply/houdun";
import zuansu from "@/views/ClaimSettlement/apply/zuansu";

export default {
  name: "ClaimSettlementApply",
  props: {
    type: String,
  },
  components: {
    //todo 新增保险要改的地方
    brokenScreen,
    iphone,
    upServe,
    android,
    androidYearChange,
    iphoneYearChange,
    smartChoice,
    huawei,
    iphoneSmart,
    yanbaofuwu,
    dianchifuwu,
    houdun,
    zuansu
  },
  data() {
    return {
      notice: "",
      types: {
        brokenScreen: {
          title: "碎屏险换屏申请",
        },
        iphone: {
          title: "苹果换机服务履约",
        },
        upServe: {
          title: "换机服务履约",
        },
        android: {
          title: "安卓全面保服务履约",
        },
        androidYearChange: {
          title: "安卓保值焕新服务履约",
        },
        iphoneYearChange: {
          title: "苹果年年焕新服务履约",
        },
        smartChoice: {
          title: "智选换机换屏保障服务履约",
        },
        huawei: {
          title: "智选华为售后服务履约",
        },
        iphoneSmart: {
          title: "智选苹果售后服务履约",
        },
        yanbaofuwu: {
          title: "延保服务履约",
        },
        dianchifuwu: {
          title: "电池服务履约",
        },
        houdun: {
          title: "后盾保障计划",
        },
        zuansu: {
          title: "专属权益履约",
        },
        //todo 新增保险要改的地方
      },
      typeData: {},
    };
  },
  mounted() {
    this.typeData = this.types[this.type];
    console.log(this.type);
    console.log(this.typeData);
    window.document.title = this.typeData.title;
    console.log(window.document.title);
    article(3).then((res) => {
      this.notice = res.data && res.data.content;
    });
  },
  methods: {},
};
</script>

<style scoped></style>
