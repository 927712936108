import { isFilled } from "@/utils";

let mixin = {
    watch: {
        // orderData: {
        //     handler(val) {
        //         if (JSON.stringify(val) !== '{}') {
        //
        //
        //         }
        //     },
        //     immediate: true,
        //     deep: true
        // },
        form: {
            handler(form) {
                this.checkForm(form)
            },
            deep: true,
        }
    },
    methods: {
        checkForm(form) {
            // console.clear()
            let isDone = true
            console.warn('--未完成--')
            Object.keys(form).forEach(key => {
                if (!isFilled(form[key])) {
                    if (this.requiredSet.has(key)) {
                        console.log(key)
                        isDone = false
                    }
                }
            })
            this.isDone = isDone
        },
        validateForm() {
            // if (!new RegExp(/^([\u4E00-\uFA29]|[\uE7C7-\uE7F3])*$/).test(this.form.name.trim())) {
            //     this.$toast('请输入正确中文名')
            //     return false;
            // }
            if (!new RegExp(/^[1][3456789][0-9]{9}$/).test(this.form.phone)) {
                this.$toast('请输入正确手机号码')
                return false;
            }
            return true
        }
    }
}
export default mixin
