<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30">注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice>
    <pic-uploader @change="url => form.phoneFrontPic = url" :src="form.phoneFrontPic"
      :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="url => form.idCardPic = url" :src="form.idCardPic"
      :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title>接件单正面照<span class="non-essential">(非必填)</span></section-title>
    <notice class="mb-30">注：上传接件单正面照</notice>
    <pic-uploader @change="url => form.orderFrontPic = url" :src="form.orderFrontPic"
      :example="require('@/assets/images/examplePic/example7.jpg')"></pic-uploader>

    <template v-if="requiredSet.has('aisiScreenShoot')">
      <section-title show-asterisk>爱思助手/沙漏验机截图</section-title>
      <notice class="mb-30">注：请上传爱思助手/沙漏验机点击【查看验机报告】后截图</notice>
      <pic-uploader @change="url => form.aisiScreenShoot = url" :src="form.aisiScreenShoot"
        :example="require('@/assets/images/examplePic/example8.png')"></pic-uploader>
    </template>

    <section-title show-asterisk>理赔信息</section-title>
    <!--    <cell-input title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

        <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

        <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

        <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname" @change="equityChange"></multiple-choice>
    <van-cell title="姓名" :value="form.name || '--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard || '--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI || '--'"></van-cell>

    <select-search-cell title="手机品牌" :disabled="true" v-model="form.brandId" placeholder="请输入查询或选择手机品牌" :options="brandList"
      @change="brandChange" label-key="brand" id-key="brand_id"></select-search-cell>

    <select-search-cell title="手机型号" :disabled="true" v-model="form.modelId" placeholder="请输入查询或选择手机型号" :options="modelList"
      label-key="model_name" id-key="model_id"></select-search-cell>

    <!--    <cell-input title="手机串号" v-model="form.serialNumber" placeholder="请输入手机串号"></cell-input>-->


    <multiple-choice title="子险种" :is-collapse="true" placeholder="请选择子险种" v-model="form.son_strs"
      :options="subInsuranceList" id-key="son_id" name-key="son_name" required-key="is_require"></multiple-choice>

    <field-piker label="服务选择" title="请选择服务选择" v-model="form.service" placeholder="请选择服务选择" :columns="serviceList"
      @getMessage="serviceChange"></field-piker>


    <cell-input title="延保原因" v-if="requiredSet.has('ybao_reason')" v-model="form.ybao_reason"
      placeholder="请填写延保原因(100字以内)" maxlength="100"></cell-input>

    <!--    <template v-if="form.service===serviceList[1]">-->
    <template v-if="requiredSet.has('phoneBackPic')">
      <section-title show-asterisk>手机后盖照片</section-title>
      <notice class="mb-30">注：上传手机后盖照片</notice>
      <pic-uploader @change="url => form.phoneBackPic = url" :src="form.phoneBackPic"
        :example="require('@/assets/images/examplePic/example6.jpg')"></pic-uploader>
    </template>

    <!--    <field-piker label="手机故障" title="请选择手机故障" v-model="form.fault" placeholder="请选择手机故障"-->
    <!--                 :columns="faultList" @getMessage="faultChange"></field-piker>-->

    <multiple-choice title="手机故障" placeholder="请选择手机故障" v-model="form.fault" :options="faultList" id-key="damage_id"
      name-key="damage_title" @change="faultChange"></multiple-choice>

    <get-city title="维修城市" v-model="form.address"></get-city>

    <my-button class="confirm-btn" :type="isDone ? 'primary' : 'default'" :disabled="!isDone" @click="confirm">提交申请
    </my-button>
  </div>
</template>

<script>

import { getPhoneBrand, getPhoneModel, brokenEquity } from "@/api";
import {
  checkOrder,
  damageConfig2,
  editOrderClaims,
  getOrderClaimsInfo,
  getOrderInfo2,
  setOrderClaims, spbService2
} from "@/api/claimSettlement";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "iphoneSmart",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      phoneFrontPic: '',
      idCardPic: '',
      orderFrontPic: '',
      name: '',
      idCard: '',
      phone: '',
      IMEI: '',
      brandId: '',
      modelId: '',
      // serialNumber: '',
      service: '',
      fault: [],
      // maintainMethod: '',
      address: {},
      son_strs: [],
      ybao_reason: '',
      aisiScreenShoot: ''
    }
    return {
      instype_id: '',//保险类型ID
      // 权益选择
      equityList: [],
      form,
      formItems: {},
      subInsuranceList: [],
      brandList: [],
      modelList: [],
      //todo
      serviceList: [],
      faultList: [],
      // maintainList: ['在购机门店维修', '本地维修点维修', '邮寄至成都总部维修'],

      //非必填列表
      requiredSet: new Set(Object.keys(form).filter(item => {
        return ['orderFrontPic', 'address', 'ybao_reason'].indexOf(item) === -1
      })),
      //是否已填完
      isDone: false,
      type: 'add',
      hasYearChange: true
    }
  },
  mounted() {
    // instype_id 保险类型ID
    this.instype_id = this.$route.query.instype_id
    let request
    let type = this.$route.query.claims_id ? 'edit' : 'add'
    this.type = type
    if (type === 'add') {
      request = getOrderInfo2({
        id: this.$route.query.id,
        instype_id: this.$route.query.instype_id
      })
    } else {
      request = getOrderClaimsInfo(this.$route.query.claims_id)
    }
    request.then(res => {
      let data = type === 'add' ? res.data : res.data.order
      this.orderData = data
      if (data) {
        this.form.name = data.name || ''
        this.form.idCard = data.id_card || ''
        this.form.phone = data.phone || ''
        this.form.IMEI = data.imei || ''
        this.form.brandId = data.brand_id || ''
        this.form.modelId = data.model_id || ''
        this.form.phoneFrontPic = data.img || ''
        this.form.phoneBackPic = data.back || ''
        this.form.idCardPic = data.card || ''
        this.form.aisiScreenShoot = data.elsie || ''
        this.form.orderFrontPic = data.receive || ''
        this.form.service = data.ins_service_name || ''
        this.form.ybao_reason = data.ybao_reason || ''
        this.form.fault = data.damage_title && data.damage_title.split(',').map(item => ({
          damage_title: item
        })) || []
        // this.form.address = data.repair_city||''

        this.subInsuranceList = data.son
        let arr = data.son_strs.split(',')
        this.form.son_strs = data.son.filter(item => {
          return arr.indexOf(item.son_id + '') !== -1
        })

        if (data.ins_service_name === '免费延保') {
          this.requiredSet.add('ybao_reason')
        }
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
        this.equityChange(this.form.equity)
        this.init();
        // 获取权益选择
        this.getEquity()
      } else {
        this.$toast(/*res.msg||*/'无相关订单信息')
      }
    });
  },
  methods: {
    // 获取权益选择
    getEquity() {
      console.log(this.instype_id, 'this.instype_id');
      brokenEquity({ instype_id: this.instype_id }).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if (item.eqname == el.eqname) {
                item.id = el.id
              }
            })
          })
        }
        console.log(this.equityList, 'qqqqqqqqq');
      })
    },
    init() {
      this.getBrand()
      this.getModel()
      this.getServiceList()
      this.getFaultList()
      this.checkOrder().finally(() => {
        this.serviceChange(this.form.service)
        this.faultChange(this.form.fault)
        this.equityChange(this.form.equity)
      })
    },
    getServiceList() {
      spbService2().then(res => {
        this.serviceList = res.data
      })
    },
    getFaultList() {
      damageConfig2().then(res => {
        let faultList = res.data || []
        this.form.fault.forEach(item => {
          let findResult = faultList.find(f => {
            return f.damage_title === item.damage_title
          })
          if (findResult) {
            item.damage_id = findResult.damage_id
          }
        })

        this.faultList = faultList
      })
    },
    checkOrder() {
      return checkOrder(1, this.form.IMEI).then(res => {
        this.hasYearChange = res.data !== 0
      })
    },
    getBrand() {
      getPhoneBrand({ type: this.orderData.type }).then(res => {
        this.brandList = res.data.brandList
      })
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({ brand_id }).then(res => {
        this.modelList = res.data
      })
    },
    brandChange(data) {
      this.modelList = []
      this.$set(this.form, 'modelId', '')
      this.getModel(data.brand_id)
    },
    // 权益选择变化绑定爱思和手机后盖照片
    equityChange() { },
    serviceChange(data) {
      if (data === this.serviceList[1]) {
        this.requiredSet.add('ybao_reason')
      } else {
        this.requiredSet.delete('ybao_reason')
        this.form.ybao_reason = ''
      }
    },
    faultChange(data) {
      if (this.form.fault.length >= 2) {
        if (this.hasYearChange) {
          this.$myDialog({
            type: 'text',
            title: '年年焕新提示',
            isShowCancel: true,
            message: `系统检测您享有年年焕新服务，是否选择更换新机？`,
            confirmButtonText: '是',
            cancelButtonText: '否',
            beforeClose: (action, done) => {
              if (action === 'confirm') {

                window.location.href = window.location.href.replace('brokenScreen', `${this.orderData.type === 1 ? 'iphone' : 'android'}YearChange`)
                  .replace(/instype_id=\d+/, 'instype_id=4')
                done()
              } else {
                setTimeout(() => {
                  this.repairDialog()
                  done()
                }, 300)

                done()
              }
            }
          });
        } else {
          this.repairDialog()
        }

      }
    },
    repairDialog() {
      this.$myDialog({
        type: 'text',
        title: '维修提醒',
        message: `您当前设备状态存在多处损坏可能存在权益保障以外的维修，需由您自行承担维修费用，请您知晓！`,
        confirmButtonText: '确定',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            done()
          } else {

            done()
          }
        }
      })
    },
    confirm() {
      if (!this.validateForm()) {
        return
      }
      let { userInfo } = this.$store.state
      let { form } = this
      let request
      let params = {
        tem_img: form.phoneFrontPic,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service,
        damage_title: form.fault.map(item => (item.damage_title)).join(','),
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode:form.maintainMethod,
        repair_city: form.address.city,
        gps: form.address.detail,
        son_strs: form.son_strs.map(item => (item.son_id)).join(','),
        ybao_reason: form.ybao_reason,
        tem_elsie: form.aisiScreenShoot,
      }
      if (this.type === 'add') {
        params.openid = userInfo.openid
        params.nickname = userInfo.nickname
        params.headimg = userInfo.headimg
        request = setOrderClaims(params)
      } else {
        params.claims_id = this.orderData.claims_id
        request = editOrderClaims(params)
      }
      request.then(res => {
        if (res && res.code) {
          this.$myDialog({
            type: 'success',
            title: '提交成功，正在审核中，请稍等...',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                done()

                // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                this.$router.push('/home');
              } else {
                done()
              }
            }
          });
        } else {
          this.$toast(res.msg || '审核申请提交失败');
        }
      }).catch(err => {
        this.$toast(err && err.msg || '审核申请提交失败');
      })
    }
  }
}
</script>

<style scoped>
.mb-30 {
  margin-bottom: 30px;
}

.non-essential {
  font-size: 28px;
  margin-left: 20px;
  color: #aaa;
  font-weight: 100;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}
</style>
