import { render, staticRenderFns } from "./iphoneSmart.vue?vue&type=template&id=774dffa5&scoped=true&"
import script from "./iphoneSmart.vue?vue&type=script&lang=js&"
export * from "./iphoneSmart.vue?vue&type=script&lang=js&"
import style0 from "./iphoneSmart.vue?vue&type=style&index=0&id=774dffa5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774dffa5",
  null
  
)

export default component.exports