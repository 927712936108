<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30">注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice>
    <pic-uploader @change="url=>form.phoneFrontPic=url" :src="form.phoneFrontPic"
                  :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="url=>form.idCardPic=url" :src="form.idCardPic"
                  :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title show-asterisk>理赔信息</section-title>
<!--    <cell-input :disabled="true" title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

    <scan-id-card-number :disabled="true" v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

    <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname" @change="equityChange"></multiple-choice>
    <van-cell title="姓名" :value="form.name||'--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard||'--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone||'--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI||'--'"></van-cell>

    <select-search-cell  title="手机品牌" v-model="form.brandId" :disabled="true" placeholder="请输入查询或选择手机品牌" :options="brandList"
                        @change="brandChange"
                        label-key="brand" id-key="brand_id"></select-search-cell>

    <select-search-cell  title="手机型号" v-model="form.modelId" :disabled="true" placeholder="请输入查询或选择手机型号" :options="modelList"
                        label-key="model_name"
                        id-key="model_id"></select-search-cell>

    <field-piker label="损坏情况" title="请选择损坏情况" v-model="form.fault" placeholder="请选择损坏情况"
                 :columns="faultList" @getMessage="faultChange"></field-piker>

    <template v-if="isBackDamaged">
      <section-title show-asterisk>手机后盖照片</section-title>
      <notice class="mb-30">注：上传手机后盖照片</notice>
      <pic-uploader @change="url=>form.phoneBackPic=url" :src="form.phoneBackPic"
                    :example="require('@/assets/images/examplePic/example6.jpg')"></pic-uploader>
    </template>

    <field-piker label="理赔服务" title="请选择理赔服务" v-model="form.service" placeholder="请选择理赔服务"
                 :columns="serviceList" @getMessage="serviceChange"></field-piker>

    <money title="新机价格" placeholder="请输入新机价格" v-model="form.money" color="#333" v-if="requiredSet.has('money')"></money>

    <template v-if="isChangeScreen">

      <section-title>接件单正面照<span class="non-essential">(非必填)</span></section-title>
      <notice class="mb-30">注：上传接件单正面照</notice>
      <pic-uploader @change="url=>form.orderFrontPic=url" :src="form.orderFrontPic"
                    :example="require('@/assets/images/examplePic/example7.jpg')"></pic-uploader>
    </template>

    <!--    <get-address title="维修城市" v-model="form.address"></get-address>-->
    <get-city title="维修城市" v-model="form.address"></get-city>

    <my-button class="confirm-btn" :type="isDone?'primary':'default'" :disabled="!isDone"
               @click="confirm">提交申请
    </my-button>
  </div>
</template>

<script>

import {getPhoneBrand, getPhoneModel, brokenEquity } from "@/api";
import {
  damageConfig,
  editOrderClaims,
  getOrderClaimsInfo,
  getOrderInfo2,
  insService,
  setOrderClaims
} from "@/api/claimSettlement";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "Android",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      phoneFrontPic: '',
      idCardPic: '',
      phoneBackPic: '',
      orderFrontPic: '',
      name: '',
      idCard: '',
      phone: '',
      IMEI: '',
      brandId: '',
      modelId: '',
      service: '',
      fault: '',
      address: {},
      money: '',
    }
    return {
      isBackDamaged:false,//手机后盖显示
      instype_id:'',//保险类型ID
       // 权益选择
       equityList: [],
      form,
      brandList: [],
      modelList: [],
      serviceList: [],
      faultList: [],
      upgradeTypeList: ['同代同型换新机', '迭代升级换新机'],
      upgradeRAMList: [],
      //必填集合
      requiredSet: new Set(Object.keys(form).filter(item => {
        return ['orderFrontPic', 'phoneBackPic','money','address'].indexOf(item) === -1
      })),
      //是否已填完
      isDone: false,
      newPhonePrice: '',
      oldPhonePrice: '',
      differenceOfPrices: '',
      insurancePayout: '',
      type: 'add'
    }
  },

  computed: {
    // isBackDamaged() {
    //   return this.form.fault.indexOf('后盖破裂') !== -1
    // },
    isChangeScreen() {
      return this.form.service.indexOf('免费换屏') !== -1
    }
  },
  mounted() {
     // instype_id 保险类型ID
     this.instype_id=this.$route.query.instype_id
    let request
    let type = this.$route.query.claims_id ? 'edit' : 'add'
    this.type = type
    if (type === 'add') {
      request = getOrderInfo2({
        id: this.$route.query.id,
        instype_id: this.$route.query.instype_id
      })
    } else {
      request = getOrderClaimsInfo(this.$route.query.claims_id)
    }
    request.then(res => {
      let data = type === 'add' ? res.data : res.data.order
      this.orderData = data
      if (data) {
        this.form.name = data.name || ''
        this.form.idCard = data.id_card || ''
        this.form.phone = data.phone || ''
        this.form.IMEI = data.imei || ''
        this.form.brandId = data.brand_id || ''
        this.form.modelId = data.model_id || ''
        // this.form.money = data.bare_price || ''
        this.form.phoneFrontPic = data.img || ''
        this.form.phoneBackPic = data.back || ''
        this.form.idCardPic = data.card || ''
        this.form.orderFrontPic = data.receive || ''
        this.form.aisiScreenShoot = data.elsie || ''
        this.form.service = data.ins_service_name || ''
        this.form.fault = data.damage_title || ''
        // this.form.address = data.repair_city||''
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
          this.equityChange(this.form.equity)
        this.init()
          // 获取权益选择
          this.getEquity()
      } else {
        this.$toast(/*res.msg||*/'无相关订单信息')
      }
    });
  },
  methods: {
      // 获取权益选择
      getEquity() {
      console.log(this.instype_id,'this.instype_id');
      brokenEquity({instype_id:this.instype_id}).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if(item.eqname==el.eqname){
                item.id=el.id
              }
            })
          })
        }
        console.log(this.equityList, 'qqqqqqqqq');
      })
    },
    init() {
      this.getBrand()
      this.getModel()
      this.getServiceList();
      this.getFaultList()
      this.getModel()
    },
    getBrand() {
      getPhoneBrand({type: this.orderData.type}).then(res => {
        this.brandList = res.data.brandList
        let ramList = res.data.ramList || []
        this.upgradeRAMList = ramList.map(i => (i.ram_size))
      })
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({brand_id}).then(res => {
        this.modelList = res.data
      })
    },
    getServiceList() {
      insService(this.orderData.instype_id).then(res => {
        let list = res.data || []
        this.serviceList = list.map(item => (item.ins_service_name))
      })
    },
    getFaultList() {
      damageConfig().then(res => {
        let list = res.data || []
        this.faultList = list.map(item => (item.damage_title))
      })
    },
    brandChange(data) {
      this.modelList = []
      this.$set(this.form, 'modelId', '')
      this.getModel(data.brand_id)
    },
       // 权益选择变化绑定手机后盖照片
       equityChange() {
      console.log(this.form.equity, 'form.equity');
      const hasPhoneBackPic = this.form.equity.some(item => item.eqname === '后盖');
      if (hasPhoneBackPic) {
        this.isBackDamaged=true;
      } else {
        this.isBackDamaged=false;
      }
    },
    serviceChange(data) {
      if (data === this.serviceList[3]) {
        this.requiredSet.add('money');
      } else {
        this.requiredSet.delete('money')
        this.form.money=''
      }
    },
    faultChange() {
      if (this.isBackDamaged) {
        this.requiredSet.add('phoneBackPic')
      } else {
        this.requiredSet.delete('phoneBackPic')
      }
    },
    confirm() {
      if (!this.validateForm()) {
        return
      }
      let {userInfo} = this.$store.state;
      let {form} = this

      let request
      let params = {
        tem_img: form.phoneFrontPic,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        tem_back:form.phoneBackPic,
        // tem_elsie: form.aisiScreenShoot,
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service,
        damage_title: form.fault,
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode: form.maintainMethod,
        repair_city: form.address.city,
        gps:form.address.detail,

        // is_up:form.upgradeType,
        // intact:form.fault,
        // new_model:form.upgradeModel.model_id,
        // new_ram:form.upgradeRAM,
        new_price: form.money,
        // bare_price:this.oldPhonePrice,
        // money:this.differenceOfPrices,
        // claims_money:this.insurancePayout
      }
      if (this.type === 'add') {
        params.openid = userInfo.openid
        params.nickname = userInfo.nickname
        params.headimg = userInfo.headimg
        request = setOrderClaims(params)
      } else {
        params.claims_id = this.orderData.claims_id
        request = editOrderClaims(params)
      }
      request.then(res => {
        if (res && res.code) {
          this.$myDialog({
            type: 'success',
            title: '提交成功，正在审核中，请稍等...',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                done()

                // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                this.$router.push('/home');

              } else {
                done()
              }
            }
          });
        } else {
          this.$toast(res.msg || '审核申请提交失败');
        }
      }).catch(err => {
        this.$toast(err && err.msg || '审核申请提交失败');
      });
    }
  }
}
</script>

<style scoped>
.mb-30 {
  margin-bottom: 30px;
}

.non-essential {
  font-size: 28px;
  margin-left: 20px;
  color: #aaa;
  font-weight: 100;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}
</style>
