<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30"
      >注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice
    >
    <pic-uploader
      @change="(url) => (form.phoneFrontPic = url)"
      :src="form.phoneFrontPic"
      :example="require('@/assets/images/examplePic/example3.png')"
    ></pic-uploader>
    <template v-if="form.service == '质量问题换机'">
      <notice class="mb-30">注: 设置界面显示出序列号，电池出险，换机出险，均需如此操</notice>
      <pic-uploader @change="(url) => (form.tem_img2 = url)" :src="form.tem_img2"
        :example="require('@/assets/images/examplePic/tem_img2.jpg')"></pic-uploader>
    </template>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader
      @change="(url) => (form.idCardPic = url)"
      :src="form.idCardPic"
      :example="require('@/assets/images/examplePic/example5.jpg')"
    ></pic-uploader>

    <section-title
      >接件单正面照<span class="non-essential">(非必填)</span></section-title
    >
    <notice class="mb-30">注：上传接件单正面照</notice>
    <pic-uploader
      @change="(url) => (form.orderFrontPic = url)"
      :src="form.orderFrontPic"
      :example="require('@/assets/images/examplePic/example7.jpg')"
    ></pic-uploader>
    <template>
      <section-title show-asterisk>爱思助手/沙漏验机截图</section-title>
      <notice class="mb-30"
        >注：请上传爱思助手/沙漏验机点击【查看验机报告】后截图</notice
      >
      <pic-uploader
        @change="(url) => (form.aisiScreenShoot = url)"
        :src="form.aisiScreenShoot"
        :example="require('@/assets/images/examplePic/example8.png')"
      ></pic-uploader>
    </template>
    <section-title show-asterisk>理赔信息</section-title>
    <!--    <cell-input title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

    <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname" @change="equityChange"></multiple-choice>
    <van-cell title="姓名" :value="form.name || '--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard || '--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI || '--'"></van-cell>

    <select-search-cell
      title="手机品牌"
      :disabled="true"
      v-model="form.brandId"
      placeholder="请输入查询或选择手机品牌"
      :options="brandList"
      @change="brandChange"
      label-key="brand"
      id-key="brand_id"
    ></select-search-cell>

    <select-search-cell
      title="手机型号"
      :disabled="true"
      v-model="form.modelId"
      placeholder="请输入查询或选择手机型号"
      :options="modelList"
      label-key="model_name"
      id-key="model_id"
    ></select-search-cell>

    <!--    <cell-input title="手机串号" v-model="form.serialNumber" placeholder="请输入手机串号"></cell-input>-->

    <field-piker
      label="服务选择"
      title="请选择服务选择"
      v-model="form.service"
      placeholder="请选择服务选择"
      :columns="serviceList"
    ></field-piker>

    <template v-if="form.service == '质量问题换机'">
      <section-title show-asterisk>附加摄像头、面容、边框图片</section-title>
      <notice class="mb-30">注：需要拍摄面容、摄像头、左右边框、顶部、底部图片</notice>
      <div style="display: flex;overflow-x: auto;">
        <pic-uploader v-for="(item, index) in imgData" :key="index" @change="(url) => (form.additional[index] = url)" :src="form.additional[index]" :isExaple="false"></pic-uploader>
      </div>
      <div class="tabimg">
        <div v-for="(item,index) in imgData" :key="index" >
          <img class="imags" :src="item.url" alt="" preview="1">
          <p style="margin-left: 0.55rem; color: red">{{item.title}}</p>
        </div>
      </div>
    </template>

    <template v-if="form.service == '质量问题换机'">
      <section-title show-asterisk>上传损坏视频</section-title>
      <video-uploader :src="form.test_video" @change="(url) => { form.test_video = url; }"></video-uploader>
      <section-title show-asterisk>上传手机外观视频</section-title>
      <video-uploader :src="form.test_video2" @change="(url) => { form.test_video2 = url; }"></video-uploader>
    </template>

    <template v-if="form.service == '质量问题换机'">
      <field-piker label="迭代升级" title="请选择迭代升级方式" v-model="form.is_up" placeholder="请选择迭代升级方式"
        :columns="upgradeTypeList"></field-piker>

      <template v-if="form.is_up === '迭代升级换新机'">
        <select-search-cell
          title="升级型号"
          v-model="form.new_model"
          placeholder="请选择升级型号"
          :options="modelList"
          label-key="model_name"
          id-key="model_id"
        ></select-search-cell>

        <select-search-cell
          title="新机内存"
          v-model="form.new_ram"
          placeholder="请选择新机内存"
          :options="upgradeRAMList"
          label-key="ram_size"
          id-key="ram_size"
        ></select-search-cell>
      </template>

      <van-cell title="新机价格">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="form.new_price" placeholder="新机价格"
            disabled>
        </template>
      </van-cell>
      <van-cell title="旧机价格">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="form.bare_price" placeholder="旧机价格"
            disabled>
        </template>
      </van-cell>
      <van-cell title="用户补交金额">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="form.money"
            placeholder="用户补交金额" disabled>
        </template>
      </van-cell>
      <van-cell title="保险赔付金额">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="form.claims_money"
            placeholder="保险赔付金额" disabled>
        </template>
      </van-cell>
    </template>

    <template v-if="requiredSet.has('phoneBackPic')">
      <section-title show-asterisk>手机后盖照片</section-title>
      <notice class="mb-30">注：上传手机后盖照片</notice>
      <pic-uploader
        @change="(url) => (form.phoneBackPic = url)"
        :src="form.phoneBackPic"
        :example="require('@/assets/images/examplePic/example6.jpg')"
      ></pic-uploader>
    </template>

    <multiple-choice
      title="手机故障"
      placeholder="请选择手机故障"
      v-model="form.fault"
      :options="faultList"
      id-key="damage_id"
      name-key="damage_title"
      @change="faultChange"
    ></multiple-choice>

    <get-city title="维修城市" v-model="form.address"></get-city>

    <my-button
      class="confirm-btn"
      :type="isDone ? 'primary' : 'default'"
      :disabled="!isDone"
      @click="confirm"
      >提交申请
    </my-button>
  </div>
</template>

<script>
import { getPhoneBrand, getPhoneModel,brokenEquity } from "@/api";
import {
  checkOrder,
  damageConfig2,
  editOrderClaims,
  getOrderClaimsInfo,
  getOrderInfo2,
  setOrderClaims,
  spbService,
  yanPrice,
} from "@/api/claimSettlement";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "BrokenScreen",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      phoneFrontPic: "",
      tem_img2: '',
      idCardPic: "",
      orderFrontPic: "",
      phoneBackPic: "",
      name: "",
      idCard: "",
      phone: "",
      IMEI: "",
      brandId: "",
      modelId: "",
      // serialNumber: '',
      service: "",
      fault: [],
      // maintainMethod: '',
      address: {},
      aisiScreenShoot: "",

      additional: [],
      test_video: '',
      test_video2: '',
      is_up: '',
      new_model: '',
      new_ram: '',
      new_price: '',
      bare_price: '',
      money: '',
      claims_money: ''
    };
    return {
      imgData:[
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/a56bc1e1bd6a67b40ed25ae6b2e08a79.jpg?x-oss-process=image/quality,q_50',
          title: '面容'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/c520207c036e46f1e14673f277d6c025.jpg?x-oss-process=image/quality,q_50',
          title: '边框-左'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/a4d6b3df945d3871fa441ec4553c9246.jpg?x-oss-process=image/quality,q_50',
          title: '摄像头'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/fa18871ed915301891197d614e84f49c.jpg?x-oss-process=image/quality,q_50',
          title: '边框-右'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20240909/b67e939c6c240f779364853c51c9db1a.jpg?x-oss-process=image/quality,q_50',
          title: '顶部'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20240909/6dbfd2dd758e316db217a116f3c0df40.jpg?x-oss-process=image/quality,q_50',
          title: '底部'
        }
      ],
      upgradeTypeList: ['同代同型换新机', '迭代升级换新机'],
      instype_id:'',//保险类型ID
      // 权益选择
      equityList: [],
      form,
      formItems: {},
      brandList: [],
      modelList: [],
      upgradeRAMList: [],
      //todo
      serviceList: [],
      faultList: [],
      // maintainList: ['在购机门店维修', '本地维修点维修', '邮寄至成都总部维修'],

      //非必填列表
      requiredSet: new Set(
        Object.keys(form).filter((item) => {
          return (
            [
              "orderFrontPic",
              "aisiScreenShoot",
              "phoneBackPic",
              "address",

              "additional",
              "test_video",
              "test_video2",
              "is_up",
              "new_model",
              "new_ram",
              "new_price",
              "bare_price",
              "money",
              "claims_money",
              "tem_img2"
            ].indexOf(item) === -1
          );
        })
      ),
      //是否已填完
      isDone: false,
      type: "add",
      hasYearChange: true,
    };
  },
  watch: {
    'form.is_up': {
      handler() {
        this.getModel()
        this.getPrice()
      }
    },
    'form.new_model': {
      handler() {
        this.getPrice()
      }
    },
    'form.new_ram': {
      handler() {
        this.getPrice()
      }
    },
  },
  mounted() {
     // instype_id 保险类型ID
     this.instype_id=this.$route.query.instype_id
    let request;
    let type = this.$route.query.claims_id ? "edit" : "add";
    this.type = type;
    if (type === "add") {
      request = getOrderInfo2({
        id: this.$route.query.id,
        instype_id: this.$route.query.instype_id,
      });
    } else {
      request = getOrderClaimsInfo(this.$route.query.claims_id);
    }
    request.then((res) => {
      let data = type === "add" ? res.data : res.data.order;
      this.orderData = data;
      if (data) {
        this.form.name = data.name || "";
        this.form.idCard = data.id_card || "";
        this.form.phone = data.phone || "";
        this.form.IMEI = data.imei || "";
        this.form.brandId = data.brand_id || "";
        this.form.modelId = data.model_id || "";
        this.form.phoneFrontPic = data.img || "";
        this.form.tem_img2 = data.tem_img2 || ''
        this.form.phoneBackPic = data.back || "";
        this.form.idCardPic = data.card || "";
        this.form.orderFrontPic = data.receive || "";
        this.form.aisiScreenShoot = data.elsie || "";
        this.form.service = data.ins_service_name || "";

        this.form.additional = data.additional || [];
        this.form.test_video = data.test_video || "";
        this.form.test_video2 = data.test_video2 || "";
        this.form.is_up = data.is_up || "";
        this.form.new_model = data.new_model || "";
        this.form.new_ram = data.new_ram || "";
        this.form.new_price = data.new_price || "";
        this.form.bare_price = data.bare_price || "";
        this.form.money = data.money || "";
        this.form.claims_money = data.claims_money || "";

        this.form.fault =
          (data.damage_title &&
            data.damage_title.split(",").map((item) => ({
              damage_title: item,
            }))) ||
          [];
        // this.form.address = data.repair_city||''
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
          this.equityChange(this.form.equity)
        this.init();
             // 获取权益选择
             this.getEquity()
      } else {
        this.$toast(/*res.msg||*/ "无相关订单信息");
      }
    });
  },
  methods: {
     // 获取权益选择
     getEquity() {
      console.log(this.instype_id,'this.instype_id');
      brokenEquity({instype_id:this.instype_id}).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if(item.eqname==el.eqname){
                item.id=el.id
              }
            })
          })
        }
      })
    },
    init() {
      this.getBrand();
      this.getModel();
      this.getServiceList();
      this.getFaultList();
      this.checkOrder().finally(() => {
        // this.serviceChange(this.form.service);
        this.faultChange(this.form.fault);
        this.equityChange(this.form.equity)
      });
    },
    getServiceList() {
      spbService({
        order_id: this.orderData.order_id
      }).then((res) => {
        this.serviceList = res.data;
      });
    },
    // upgradeTypeChange() {
    //   this.getModel()
    //   this.getPrice()
    // },
    getPrice() {
      yanPrice({
        order_id: this.orderData.order_id,
        is_up: this.form.is_up,
        new_model: this.form.new_model,
        new_ram: this.form.new_ram,
      }).then(res => {
        const data = res.data || {
          bare_price: "",
          claims_money: "",
          money: "",
          new_price: ""
        }
        this.form.bare_price = data.bare_price
        this.form.claims_money = data.claims_money
        this.form.money = data.money
        this.form.new_price = data.new_price
      }).catch(() => {
        this.form.bare_price = ""
        this.form.claims_money = ""
        this.form.money = ""
        this.form.new_price = ""
      })
    },
    getBrand() {
      getPhoneBrand({ type: this.orderData.type }).then(res => {
        this.brandList = res.data.brandList
        let ramList = res.data.ramList || []
        this.upgradeRAMList = ramList
      })
    },
    getFaultList() {
      damageConfig2().then((res) => {
        let faultList = res.data || [];
        this.form.fault.forEach((item) => {
          let findResult = faultList.find((f) => {
            return f.damage_title === item.damage_title;
          });
          if (findResult) {
            item.damage_id = findResult.damage_id;
          }
        });

        this.faultList = faultList;
      });
    },
    checkOrder() {
      return checkOrder(1, this.form.IMEI).then((res) => {
        this.hasYearChange = res.data !== 0;
      });
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({ brand_id }).then((res) => {
        this.modelList = res.data;
      });
    },
    brandChange(data) {
      this.modelList = [];
      this.$set(this.form, "modelId", "");
      this.getModel(data.brand_id);
    },
     // 权益选择变化绑定爱思和手机后盖照片
     equityChange() {
      console.log(this.form.equity, 'form.equity');
      const hasPhoneBackPic = this.form.equity.some(item => item.eqname === '后盖');
      const hasPhoneBackPicp = this.form.equity.some(item => item.eqname === '换机');
      const hasAisiScreenShoot = this.form.equity.some(item => item.eqname === '电池' && this.orderData.type === 1);

      if (hasPhoneBackPic||hasPhoneBackPicp) {
        this.requiredSet.add("phoneBackPic");
      } else {
        this.requiredSet.delete("phoneBackPic");
      }

      if (hasAisiScreenShoot) {
        this.requiredSet.add("aisiScreenShoot");
      } else {
        this.requiredSet.delete("aisiScreenShoot");
      }
    },
    faultChange(data) {
      // if (this.faultList[0] === data && this.orderData.type === 1) {
      //如果屏幕损坏不显示爱思助手截图
      if (
        !data.some((item) => item.damage_id === this.faultList[0].damage_id) &&
        this.orderData.type === 1 &&
        this.form.service === this.serviceList[2]
      ) {
        this.requiredSet.add("aisiScreenShoot");
      } else {
        this.requiredSet.delete("aisiScreenShoot");
      }
      if (this.form.fault.length >= 2) {
        if (this.hasYearChange) {
          this.$myDialog({
            type: "text",
            title: "年年焕新提示",
            isShowCancel: true,
            message: `系统检测您享有年年焕新服务，是否选择更换新机？`,
            confirmButtonText: "是",
            cancelButtonText: "否",
            beforeClose: (action, done) => {
              if (action === "confirm") {
                window.location.href = window.location.href
                  .replace(
                    "yanbaofuwu",
                    `${
                      this.orderData.type === 1 ? "iphone" : "android"
                    }YearChange`
                  )
                  .replace(/instype_id=\d+/, "instype_id=4");
                done();
              } else {
                setTimeout(() => {
                  this.repairDialog();
                  done();
                }, 300);

                done();
              }
            },
          });
        } else {
          this.repairDialog();
        }
      }
    },
    repairDialog() {
      this.$myDialog({
        type: "text",
        title: "维修提醒",
        message: `您当前设备状态存在多处损坏可能存在权益保障以外的维修，需由您自行承担维修费用，请您知晓！`,
        confirmButtonText: "确定",
        beforeClose: (action, done) => {
          if (action === "confirm") {
            done();
          } else {
            done();
          }
        },
      });
    },
    confirm() {
      if (!this.validateForm()) {
        return;
      }
      let { userInfo } = this.$store.state;
      let { form } = this;
      let request;
      let params = {
        tem_img: form.phoneFrontPic,
        tem_img2: form.tem_img2,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        tem_elsie: form.aisiScreenShoot,
        tem_back: form.phoneBackPic,
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service,
        damage_title: form.fault.map((item) => item.damage_title).join(","),
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode:form.maintainMethod,
        repair_city: form.address.city,
        gps: form.address.detail,

        additional: form.additional,
        test_video: form.test_video,
        test_video2: form.test_video2,
        is_up: form.is_up,
        new_model: form.new_model,
        new_ram: form.new_ram,
        new_price: form.new_price,
        bare_price: form.bare_price,
        money: form.money,
        claims_money: form.claims_money,
      };
      if (this.type === "add") {
        params.openid = userInfo.openid;
        params.nickname = userInfo.nickname;
        params.headimg = userInfo.headimg;
        request = setOrderClaims(params);
      } else {
        params.claims_id = this.orderData.claims_id;
        request = editOrderClaims(params);
      }
      request
        .then((res) => {
          if (res && res.code) {
            this.$myDialog({
              type: "success",
              title: "提交成功，正在审核中，请稍等...",
              confirmButtonText: "确定",
              beforeClose: (action, done) => {
                if (action === "confirm") {
                  done();

                  // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                  this.$router.push("/home");
                } else {
                  done();
                }
              },
            });
          } else {
            this.$toast(res.msg || "审核申请提交失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "审核申请提交失败");
        });
    },
  },
};
</script>

<style scoped>
.mb-30 {
  margin-bottom: 30px;
}

.non-essential {
  font-size: 28px;
  margin-left: 20px;
  color: #aaa;
  font-weight: 100;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}

.imags{
  border-radius: 10px;
  width: 2.68rem;
  height: 2.68rem;
  margin-right: 0.26667rem;
}
.tabimg{
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 3.68rem;
  overflow: auto;
}
</style>
