<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30">注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice>
    <pic-uploader @change="url => form.phoneFrontPic = url" :src="form.phoneFrontPic"
      :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>
    <template v-if="isMold">
      <notice class="mb-30">注: 设置界面显示出序列号，电池出险，换机出险，均需如此操</notice>
      <pic-uploader @change="(url) => (form.tem_img2 = url)" :src="form.tem_img2"
        :example="require('@/assets/images/examplePic/tem_img2.jpg')"></pic-uploader>
    </template>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="url => form.idCardPic = url" :src="form.idCardPic"
      :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title>接件单正面照<span class="non-essential">(非必填)</span></section-title>
    <notice class="mb-30">注：上传接件单正面照</notice>
    <pic-uploader @change="url => form.orderFrontPic = url" :src="form.orderFrontPic"
      :example="require('@/assets/images/examplePic/example7.jpg')"></pic-uploader>

    <section-title show-asterisk>爱思助手/沙漏验机截图</section-title>
    <notice class="mb-30">注：请上传爱思助手/沙漏验机点击【查看验机报告】后截图</notice>
    <pic-uploader @change="url => form.aisiScreenShoot = url" :src="form.aisiScreenShoot"
      :example="require('@/assets/images/examplePic/example8.png')"></pic-uploader>

    <section-title show-asterisk>理赔信息</section-title>
    <!--    <cell-input title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

    <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname" @change="equityChange"></multiple-choice>
    <van-cell title="姓名" :value="form.name || '--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard || '--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI || '--'"></van-cell>

    <select-search-cell title="手机品牌" :disabled="true" v-model="form.brandId" placeholder="请输入查询或选择手机品牌"
      :options="brandList" @change="brandChange" label-key="brand" id-key="brand_id"></select-search-cell>

    <select-search-cell title="手机型号" :disabled="true" v-model="form.modelId" placeholder="请输入查询或选择手机型号"
      :options="modelList" label-key="model_name" id-key="model_id"></select-search-cell>

    <field-piker label="手机故障" title="请选择手机故障" v-model="form.fault" placeholder="请选择手机故障" :columns="faultList"
      @getMessage="faultChange"></field-piker>
    <!-- 苹果2.8折换机保障计划--服务选择选了 mold为2的 -->
    <template v-if="isMold">
      <section-title show-asterisk>附加摄像头、面容、边框图片</section-title>
      <notice class="mb-30">注：需要拍摄面容、摄像头、左右边框、顶部、底部图片</notice>
      <div style="display: flex;overflow-x: auto;">
        <pic-uploader @change="(url) => (form.additional[0] = url)" :src="form.additional[0]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[1] = url)" :src="form.additional[1]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[2] = url)" :src="form.additional[2]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[3] = url)" :src="form.additional[3]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[4] = url)" :src="form.additional[4]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[5] = url)" :src="form.additional[5]"
          :isExaple="false"></pic-uploader>
      </div>
      <div class="tabimg">
        <div v-for="(item,index) in imgData" :key="index" >
          <img class="imags" :src="item.url" alt="" preview="1">
          <p style="margin-left: 0.55rem; color: red">{{item.title}}</p>
        </div>
      </div>
    </template>
    <template v-if="isBackDamaged">
      <section-title show-asterisk>手机后盖照片</section-title>
      <notice class="mb-30">注：上传手机后盖照片</notice>
      <pic-uploader @change="url => form.phoneBackPic = url" :src="form.phoneBackPic"
        :example="require('@/assets/images/examplePic/example6.jpg')"></pic-uploader>
    </template>

    <field-piker label="理赔服务" title="请选择理赔服务" v-model="form.service" placeholder="请选择理赔服务" :columns="serviceList"
      @getMessage="serviceChange" value-key="ins_service_name"></field-piker>
    <template v-if="isProportional">
      <field-piker label="迭代升级" title="请选择迭代升级方式" v-model="form.upgradeType" placeholder="请选择迭代升级方式"
        :columns="upgradeTypeList" @getMessage="upgradeTypeChange"></field-piker>

      <template v-if="isUpgrade">
        <field-piker label="升级型号" title="请选择升级型号" v-model="form.upgradeModel" placeholder="请选择升级型号" :columns="modelList"
          @getMessage="upgradeChange" value-key="model_name"></field-piker>

        <field-piker label="新机内存" title="请选择新机内存" v-model="form.upgradeRAM" placeholder="请选择新机内存"
          :columns="upgradeRAMList" @getMessage="upgradeChange"></field-piker>
      </template>

      <van-cell title="新机价格">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="newPhonePrice" placeholder="新机价格"
            disabled>
        </template>
      </van-cell>
      <van-cell title="旧机价格">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="oldPhonePrice" placeholder="旧机价格"
            disabled>
        </template>
      </van-cell>
      <van-cell title="用户补交金额">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="differenceOfPrices"
            placeholder="用户补交金额" disabled>
        </template>
      </van-cell>
      <van-cell title="保险赔付金额">
        <template #default>
          <input type="text" style="background: transparent;text-align: right" v-model="insurancePayout"
            placeholder="保险赔付金额" disabled>
        </template>
      </van-cell>
    </template>

    <!--    <get-address title="维修城市" v-model="form.address"></get-address>-->
    <get-city title="维修城市" v-model="form.address"></get-city>

    <my-button class="confirm-btn" :type="isDone ? 'primary' : 'default'" :disabled="!isDone" @click="confirm">提交申请
    </my-button>
    <!-- 同购理赔多选弹框 -->
    <van-dialog v-model="twolistShow" title="选择同购理赔(多选)" show-cancel-button confirmButtonColor="var(--base)"
      @confirm="selectConfirm" v-if="this.type === 'add' && twolistData.length > 0">
      <div class="check-box">
        <van-checkbox-group v-model="selectedTwolist" v-for="item in twolistData" :key="item.instype_id">
          <van-checkbox shape="square" :name="item.order_id">{{ item.instype_name }}</van-checkbox>
        </van-checkbox-group>
      </div>
    </van-dialog>
  </div>
</template>

<script>

import { getPhoneBrand, getPhoneModel, brokenEquity } from "@/api";
import {
  applePrice,
  // appleUpPrice,
  damageConfig, editOrderClaims,
  getOrderClaimsInfo,
  getOrderInfo2,
  insService,
  setOrderClaims
} from "@/api/claimSettlement";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "Iphone",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      // twolist:[],//同购理赔
      phoneFrontPic: '',
      tem_img2: '',
      idCardPic: '',
      orderFrontPic: '',
      phoneBackPic: '',
      aisiScreenShoot: '',
      name: '',
      idCard: '',
      phone: '',
      IMEI: '',
      brandId: '',
      modelId: '',
      service: '',
      fault: '',
      address: {},
      upgradeType: '',
      upgradeModel: '',
      upgradeRAM: ''
    }
    return {
      imgData:[
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/a56bc1e1bd6a67b40ed25ae6b2e08a79.jpg?x-oss-process=image/quality,q_50',
          title: '面容'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/c520207c036e46f1e14673f277d6c025.jpg?x-oss-process=image/quality,q_50',
          title: '边框-左'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/a4d6b3df945d3871fa441ec4553c9246.jpg?x-oss-process=image/quality,q_50',
          title: '摄像头'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/fa18871ed915301891197d614e84f49c.jpg?x-oss-process=image/quality,q_50',
          title: '边框-右'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20240909/b67e939c6c240f779364853c51c9db1a.jpg?x-oss-process=image/quality,q_50',
          title: '顶部'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20240909/6dbfd2dd758e316db217a116f3c0df40.jpg?x-oss-process=image/quality,q_50',
          title: '底部'
        }
      ],
      faultChoice: '',//手机故障选择了’后盖破裂‘显示手机后盖
      hasPhoneBackPic: false,//权益选择选择了后盖
      isBackDamaged: false,//手机后盖显示
      instype_id: '',//保险类型ID
      // 权益选择
      equityList: [],
      type: '',//add 或者 edit
      twolistShow: false,//同购理赔多选弹框
      twolistData: [],
      selectedTwolist: [],
      form,
      brandList: [],
      modelList: [],
      serviceList: [],
      faultList: [],
      upgradeTypeList: ['同代同型换新机', '迭代升级换新机'],
      upgradeRAMList: [],
      //非必填列表
      requiredSet: new Set(Object.keys(form).filter(item => {
        return ['orderFrontPic', 'upgradeType', 'phoneBackPic', 'upgradeModel', 'upgradeRAM', 'address', 'tem_img2'].indexOf(item) === -1
      })),
      //是否已填完
      isDone: false,

      newPhonePrice: '',
      oldPhonePrice: '',
      differenceOfPrices: '',
      insurancePayout: '',
      order_id: ''
    }
  },
  computed: {
    // isBackDamaged() {
    //   return this.form.fault.indexOf('后盖破裂') !== -1
    // },
    isProportional() {
      // return this.form.service.indexOf('按比例补差价换新机') !== -1
      return this.form.service.mold === 2
    },
    isUpgrade() {
      return this.isProportional && this.form.upgradeType.indexOf('迭代升级换新机') !== -1
    }
  },
  mounted() {
    // instype_id 保险类型ID
    this.instype_id = this.$route.query.instype_id
    let request
    let type = this.$route.query.claims_id ? 'edit' : 'add'
    this.type = type
    console.log(this.type, 'this.type');
    if (type === 'add') {
      request = getOrderInfo2({
        // imei: this.$route.query.imei,
        id: this.$route.query.id,
        instype_id: this.$route.query.instype_id
      })
    } else {
      request = getOrderClaimsInfo(this.$route.query.claims_id)
    }
    request.then(res => {
      // instype_id 保险类型ID
      this.instype_id = this.$route.query.instype_id
      let data = type === 'add' ? res.data : res.data.order
      this.orderData = data
      if (data) {
        this.form.name = data.name
        this.form.idCard = data.id_card
        this.form.phone = data.phone
        this.form.IMEI = data.imei
        this.form.brandId = data.brand_id
        this.form.modelId = data.model_id
        this.form.phoneFrontPic = data.img || ''
        this.form.tem_img2 = data.tem_img2 || ''
        this.form.phoneBackPic = data.back || ''
        // 新加additional
        this.form.additional = data.additional ||[];
        if(data.additional&&data.additional.length){
          this.isMold=true
         }
        this.form.idCardPic = data.card || ''
        this.form.orderFrontPic = data.receive || ''
        this.form.aisiScreenShoot = data.elsie || ''
        // this.form.service = data.ins_service_name||''
        this.form.fault = data.damage_title || ''
        this.faultChange(this.form.fault)
        // 同购理赔
        // this.form.twolist=(data.twolist &&
        //     data.twolist.split(",").map((item) => ({
        //       instype_name: item,
        //     }))) ||
        //   [];
        this.twolistData = data.twolist
        // console.log(this.twolistData,this.twolistData.length,'this.twolistData');
        this.order_id = data.order_id
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
        this.equityChange(this.form.equity)
        this.init()
        // 获取权益选择
        this.getEquity()
      } else {
        this.$toast(/*res.msg||*/'无相关订单信息')
      }
    });
  },
  methods: {
    // 获取权益选择
    getEquity() {
      console.log(this.instype_id, 'this.instype_id');
      brokenEquity({ instype_id: this.instype_id }).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if (item.eqname == el.eqname) {
                item.id = el.id
              }
            })
          })
        }
        console.log(this.equityList, 'qqqqqqqqq');
      })
    },
    bigImg (url) {
      console.log(url, 'url');
    },
    init() {
      this.getBrand()
      this.getModel()
      this.getServiceList();
      this.getFaultList()

    },
    getBrand() {
      getPhoneBrand({ type: this.orderData.type }).then(res => {
        this.brandList = res.data.brandList
        let ramList = res.data.ramList || []
        this.upgradeRAMList = ramList.map(i => (i.ram_size))
      })
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({ brand_id }).then(res => {
        this.modelList = res.data
      })
    },
    getServiceList() {
      insService(this.orderData.instype_id, this.order_id).then(res => {
        // this.serviceList = list.map(item => (item.ins_service_name))
        this.serviceList = res.data || []
      })
    },
    getFaultList() {
      damageConfig().then(res => {
        let list = res.data || []
        this.faultList = list.map(item => (item.damage_title))
      })
    },
    brandChange(data) {
      this.modelList = []
      this.$set(this.form, 'modelId', '')
      this.getModel(data.brand_id)
    },
    // 权益选择变化绑定手机后盖照片
    equityChange() {
      console.log(this.form.equity, 'form.equity');
      const hasPhoneBackPic = this.form.equity.some(item => item.eqname === '后盖');
      const hasPhoneBackPicp = this.form.equity.some(item => item.eqname === '换机');
      this.hasPhoneBackPic = hasPhoneBackPic
      if (hasPhoneBackPic || this.faultChoice === '后盖破裂'||hasPhoneBackPicp) {
        this.isBackDamaged = true;
      } else {
        this.isBackDamaged = false;
      }
    },
    serviceChange() {
      if (this.isProportional) {
        this.getApplePrice();
        if (this.isUpgrade) {
          this.requiredSet.add('upgradeModel')
          this.requiredSet.add('upgradeRAM')
          this.requiredSet.add('upgradeType')
        } else {
          this.requiredSet.add('upgradeType')
        }
      } else {
        this.requiredSet.delete('upgradeType');
        this.requiredSet.delete('upgradeModel');
        this.requiredSet.delete('upgradeRAM');
      }
      if(this.form.service.mold===2){
        this.isMold=true
      }else{
        this.isMold=false
      }
      // if (data.indexOf('按比例补差价换机') !== -1) {
      // } else {
      // }
    },
    upgradeTypeChange() {
      this.getApplePrice()
      if (this.isUpgrade) {
        this.requiredSet.add('upgradeModel')
        this.requiredSet.add('upgradeRAM')
      } else {
        this.requiredSet.delete('upgradeModel')
        this.requiredSet.delete('upgradeRAM')
      }
    },
    faultChange(data) {
      console.log(data, 'data');
      this.faultChoice = data
      if (data === '后盖破裂' || this.hasPhoneBackPic) {
        this.isBackDamaged = true;
      } else {
        this.isBackDamaged = false;
      }
      if (this.isBackDamaged) {
        this.requiredSet.add('phoneBackPic')
      } else {
        this.requiredSet.delete('phoneBackPic')
      }

      if (this.isProportional) {
        this.getApplePrice()
      }
    },
    upgradeChange() {
      this.getApplePrice()
    },
    getApplePrice() {
      let params = {
        order_id: this.orderData.order_id,
        is_up: this.form.upgradeType,
        damage_title: this.form.fault,
        ins_service_name: this.form.service.ins_service_name
      }

      if (this.isUpgrade) {
        params.new_model = this.form.upgradeModel.model_id
        params.new_ram = this.form.upgradeRAM
      }
      applePrice(params).then(res => {
        if (res && res.data) {
          this.newPhonePrice = res.data.new_price;
          this.oldPhonePrice = res.data.bare_price;
          this.differenceOfPrices = res.data.money;
          this.insurancePayout = res.data.claims_money;
        } else {
          this.newPhonePrice = '';
          this.oldPhonePrice = '';
          this.differenceOfPrices = '';
          this.insurancePayout = '';
        }
      }).catch(() => {
        this.newPhonePrice = '';
        this.oldPhonePrice = '';
        this.differenceOfPrices = '';
        this.insurancePayout = '';
      })
    },
    confirm() {
      if (this.type === 'add' && this.twolistData.length > 0) {
        this.twolistShow = true
        this.selectedTwolist = []
      } else {
        this.submitApplication()
      }
    },
    // 同购理赔多选
    selectConfirm() {
      // console.log(this.selectedTwolist,'selectedTwolist');
      // console.log(this.selectedTwolist.map((item) => item).join(","),'selectedTwolist');
      this.submitApplication()
    },
    // 提交申请
    submitApplication() {
      if (!this.validateForm()) {
        return
      }
      let { userInfo } = this.$store.state;
      let { form } = this
      let additional=[]
      if(this.isMold){
        additional=form.additional.filter(element => element !== '')
      }
      let request
      let params = {
        twolist: this.selectedTwolist.map((item) => item).join(","),
        tem_img: form.phoneFrontPic,
        tem_img2: form.tem_img2,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        tem_elsie: form.aisiScreenShoot,
        tem_back: form.phoneBackPic,
        additional: additional,//新加additional参数
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service.ins_service_name,
        damage_title: form.fault,
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode: form.maintainMethod,
        repair_city: form.address.city,
        gps: form.address.detail,

        is_up: form.upgradeType,
        // intact: form.fault,
        new_model: form.upgradeModel.model_id,
        new_ram: form.upgradeRAM,
        new_price: this.newPhonePrice,
        bare_price: this.oldPhonePrice,
        money: this.differenceOfPrices,
        claims_money: this.insurancePayout
      }
      if (this.type === 'add') {
        params.openid = userInfo.openid
        params.nickname = userInfo.nickname
        params.headimg = userInfo.headimg
        request = setOrderClaims(params)
      } else {
        params.claims_id = this.orderData.claims_id
        request = editOrderClaims(params)
      }
      request.then(res => {
        if (res && res.code) {
          this.$myDialog({
            type: 'success',
            title: '提交成功，正在审核中，请稍等...',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                done()

                // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                this.$router.push('/home');
              } else {
                done()
              }
            }
          });
        } else {
          this.$toast(res.msg || '审核申请提交失败');
        }
      }).catch(err => {
        this.$toast(err && err.msg || '审核申请提交失败');
      })
    }
  }
}
</script>

<style scoped>
.check-box {
  padding: 30px 30px 30px 60px;
}

.van-checkbox-group {
  margin-bottom: 12px !important;
}

.mb-30 {
  margin-bottom: 30px;
}
.imags{
  border-radius: 10px;
  width: 2.68rem;
  height: 2.68rem;
  margin-right: 0.26667rem;
}
.tabimg{
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 3.68rem;
  overflow: auto;
}

.non-essential {
  font-size: 28px;
  margin-left: 20px;
  color: #aaa;
  font-weight: 100;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}
</style>
