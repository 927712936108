import { render, staticRenderFns } from "./brokenScreen.vue?vue&type=template&id=da534390&scoped=true&"
import script from "./brokenScreen.vue?vue&type=script&lang=js&"
export * from "./brokenScreen.vue?vue&type=script&lang=js&"
import style0 from "./brokenScreen.vue?vue&type=style&index=0&id=da534390&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da534390",
  null
  
)

export default component.exports