<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30">注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice>
    <pic-uploader @change="(url) => (form.phoneFrontPic = url)" :src="form.phoneFrontPic"
      :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>
    <template v-if="form.service===serviceList[4]">
      <notice class="mb-30">注: 设置界面显示出序列号，电池出险，换机出险，均需如此操</notice>
      <pic-uploader @change="(url) => (form.tem_img2 = url)" :src="form.tem_img2"
        :example="require('@/assets/images/examplePic/tem_img2.jpg')"></pic-uploader>
    </template>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="(url) => (form.idCardPic = url)" :src="form.idCardPic"
      :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title>接件单正面照<span class="non-essential">(非必填)</span></section-title>
    <notice class="mb-30">注：上传接件单正面照</notice>
    <pic-uploader @change="(url) => (form.orderFrontPic = url)" :src="form.orderFrontPic"
      :example="require('@/assets/images/examplePic/example7.jpg')"></pic-uploader>

    <section-title show-asterisk>理赔信息</section-title>
    <!--    <cell-input title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

    <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname" @change="equityChange"></multiple-choice>
    <van-cell title="姓名" :value="form.name || '--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard || '--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI || '--'"></van-cell>

    <select-search-cell title="手机品牌" :disabled="true" v-model="form.brandId" placeholder="请输入查询或选择手机品牌"
      :options="brandList" @change="brandChange" label-key="brand" id-key="brand_id"></select-search-cell>

    <select-search-cell title="手机型号" :disabled="true" v-model="form.modelId" placeholder="请输入查询或选择手机型号"
      :options="modelList" label-key="model_name" id-key="model_id"></select-search-cell>

    <!--    <cell-input title="手机串号" v-model="form.serialNumber" placeholder="请输入手机串号"></cell-input>-->

    <multiple-choice title="子险种" :is-collapse="true" placeholder="请选择子险种" v-model="form.son_strs"
      :options="subInsuranceList" id-key="son_id" name-key="son_name" required-key="is_require"></multiple-choice>

    <field-piker label="服务选择" title="请选择服务选择" v-model="form.service" placeholder="请选择服务选择" :columns="serviceList"
      @getMessage="serviceChange"></field-piker>

    <template v-if="form.service == '折抵换机'">
      <van-cell title="注册价格" :value="form.bare_price || '--'"></van-cell>
      <van-cell title="折抵金额" :value="anPriceZxData.claims_money + '(' + anPriceZxData.remark + ')' || '--'
        "></van-cell>
    </template>
    <!-- 安卓亚丁智选--服务选择选了 折抵换机 -->
    <template v-if="form.service===serviceList[4]">
      <section-title show-asterisk>附加摄像头、面容、边框图片</section-title>
      <notice class="mb-30">注：需要拍摄面容、摄像头、左右边框、顶部、底部图片</notice>
      <div style="display: flex;overflow-x: auto;">
        <pic-uploader @change="(url) => (form.additional[0] = url)" :src="form.additional[0]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[1]= url)" :src="form.additional[1]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[2] = url)" :src="form.additional[2]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[3] = url)" :src="form.additional[3]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[4] = url)" :src="form.additional[4]"
          :isExaple="false"></pic-uploader>
        <pic-uploader @change="(url) => (form.additional[5] = url)" :src="form.additional[5]"
          :isExaple="false"></pic-uploader>
      </div>
      <div class="tabimg">
        <div v-for="(item,index) in imgData" :key="index" >
          <img class="imags" :src="item.url" alt="" preview="1">
          <p style="margin-left: 0.55rem; color: red">{{item.title}}</p>
        </div>
      </div>
    </template>
    <!--    <template v-if="form.service===serviceList[1]">-->
    <template v-if="requiredSet.has('phoneBackPic') || showFault">
      <section-title show-asterisk>手机后盖照片</section-title>
      <notice class="mb-30">注：上传手机后盖照片</notice>
      <pic-uploader @change="(url) => (form.phoneBackPic = url)" :src="form.phoneBackPic"
        :example="require('@/assets/images/examplePic/example6.png')"></pic-uploader>
    </template>

    <!--    <field-piker label="手机故障" title="请选择手机故障" v-model="form.fault" placeholder="请选择手机故障"-->
    <!--                 :columns="faultList" @getMessage="faultChange"></field-piker>-->

    <multiple-choice title="手机故障" placeholder="请选择手机故障" v-model="form.fault" :options="faultList" id-key="damage_id"
      name-key="damage_title" @change="faultChange"></multiple-choice>

    <!--    <template v-if="form.service===serviceList[2]">-->
    <template v-if="requiredSet.has('aisiScreenShoot')">
      <section-title show-asterisk>爱思助手/沙漏验机截图</section-title>
      <notice class="mb-30">注：请上传爱思助手/沙漏验机点击【查看验机报告】后截图</notice>
      <pic-uploader @change="(url) => (form.aisiScreenShoot = url)" :src="form.aisiScreenShoot"
        :example="require('@/assets/images/examplePic/example8.png')"></pic-uploader>
    </template>

    <!--    <field-piker label="期望维修方式" title="请选择期望维修方式" v-model="form.maintainMethod" placeholder="请选择期望维修方式"-->
    <!--                 :columns="maintainList"></field-piker>-->

    <!--    <get-address title="维修城市" v-model="form.address"></get-address>-->
    <get-city title="维修城市" v-model="form.address"></get-city>

    <my-button class="confirm-btn" @click="confirm">提交申请 </my-button>
    <!-- :type="isDone ? 'primary' : 'default'"
      :disabled="!isDone" -->
  </div>
</template>

<script>
import { getPhoneBrand, getPhoneModel, brokenEquity } from "@/api";
import {
  checkOrder,
  damageConfig2,
  editOrderClaims,
  getOrderClaimsInfo,
  getOrderInfo2,
  setOrderClaims,
  spbService,
  spbService3,
  anPriceZx,
} from "@/api/claimSettlement";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "smartChoice",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      phoneFrontPic: "",
      tem_img2: '',
      idCardPic: "",
      orderFrontPic: "",
      phoneBackPic: "",
      name: "",
      idCard: "",
      phone: "",
      IMEI: "",
      brandId: "",
      modelId: "",
      bare_price: "", //注册价格
      // serialNumber: '',
      service: "",
      fault: [],
      // maintainMethod: '',
      address: {},
      aisiScreenShoot: "",
      son_strs: [],
      anPriceZxData: {},
    };
    return {
      imgData:[
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/a56bc1e1bd6a67b40ed25ae6b2e08a79.jpg?x-oss-process=image/quality,q_50',
          title: '面容'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/c520207c036e46f1e14673f277d6c025.jpg?x-oss-process=image/quality,q_50',
          title: '边框-左'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/a4d6b3df945d3871fa441ec4553c9246.jpg?x-oss-process=image/quality,q_50',
          title: '摄像头'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20231130/fa18871ed915301891197d614e84f49c.jpg?x-oss-process=image/quality,q_50',
          title: '边框-右'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20240909/b67e939c6c240f779364853c51c9db1a.jpg?x-oss-process=image/quality,q_50',
          title: '顶部'
        },
        {
          url: 'https://ydpwkj.oss-cn-chengdu.aliyuncs.com/20240909/6dbfd2dd758e316db217a116f3c0df40.jpg?x-oss-process=image/quality,q_50',
          title: '底部'
        }
      ],
      instype_id: '',//保险类型ID
      // 权益选择
      equityList: [],
      form,
      formItems: {},
      subInsuranceList: [],
      brandList: [],
      modelList: [],
      //todo
      serviceList: [],
      faultList: [],
      // maintainList: ['在购机门店维修', '本地维修点维修', '邮寄至成都总部维修'],

      //非必填列表
      requiredSet: new Set(
        Object.keys(form).filter((item) => {
          return (
            [
              "orderFrontPic",
              "aisiScreenShoot",
              "phoneBackPic",
              "address",
              "additional",
              "tem_img2"
            ].indexOf(item) === -1
          );
        })
      ),
      //是否已填完
      // isDone: false,
      type: "add",
      hasYearChange: true,
    };
  },
  computed: {
    showFault() {
      return (this.form.fault || []).findIndex(item => item.damage_title == "后玻璃损坏") != -1
    }
  },
  mounted() {
    // instype_id 保险类型ID
    this.instype_id = this.$route.query.instype_id
    let request;
    let type = this.$route.query.claims_id ? "edit" : "add";
    this.type = type;
    if (type === "add") {
      request = getOrderInfo2({
        id: this.$route.query.id,
        instype_id: this.$route.query.instype_id,
      });
    } else {
      request = getOrderClaimsInfo(this.$route.query.claims_id);
    }
    request.then((res) => {
      let data = type === "add" ? res.data : res.data.order;
      this.orderData = data;
      if (data) {
        anPriceZx({ order_id: this.orderData.order_id }).then((res) => {
          console.log(res, "res");
          this.anPriceZxData = res.data;
        });
        this.form.name = data.name || "";
        this.form.idCard = data.id_card || "";
        this.form.phone = data.phone || "";
        this.form.IMEI = data.imei || "";
        this.form.brandId = data.brand_id || "";
        this.form.modelId = data.model_id || "";
        this.form.phoneFrontPic = data.img || "";
        this.form.tem_img2 = data.tem_img2 || ''
        this.form.phoneBackPic = data.back || "";
        // 新加additional
        this.form.additional = data.additional ||[];
        if(data.additional&&data.additional.length){
          this.isMold=true
         }
        this.form.idCardPic = data.card || "";
        this.form.orderFrontPic = data.receive || "";
        this.form.aisiScreenShoot = data.elsie || "";
        this.form.service = data.ins_service_name || "";
        this.form.bare_price = data.bare_price || "";
        this.form.fault =
          (data.damage_title &&
            data.damage_title.split(",").map((item) => ({
              damage_title: item,
            }))) ||
          [];
        // this.form.address = data.repair_city||''

        this.subInsuranceList = data.son;
        let arr = data.son_strs.split(",");
        this.form.son_strs = data.son.filter((item) => {
          return arr.indexOf(item.son_id + "") !== -1;
        });
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
        this.equityChange(this.form.equity)
        this.init();
        // 获取权益选择
        this.getEquity()
      } else {
        this.$toast(/*res.msg||*/ "无相关订单信息");
      }
    });
  },
  methods: {
    // 获取权益选择
    getEquity() {
      console.log(this.instype_id, 'this.instype_id');
      brokenEquity({ instype_id: this.instype_id }).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if (item.eqname == el.eqname) {
                item.id = el.id
              }
            })
          })
        }
        console.log(this.equityList, 'qqqqqqqqq');
      })
    },
    init() {
      this.getBrand();
      this.getModel();
      this.getServiceList();
      this.getFaultList();
      this.checkOrder().finally(() => {
        this.serviceChange(this.form.service);
        this.faultChange(this.form.fault);
        this.equityChange(this.form.equity)
      });
    },
    getServiceList() {
      //  ==5 亚丁智选专属接口 spbService3（）
      if (this.$route.query.instype_id == 5) {
        spbService3().then((res) => {
          this.serviceList = res.data;
        });
      } else {
        spbService().then((res) => {
          this.serviceList = res.data;
        });
      }
    },
    getFaultList() {
      damageConfig2().then((res) => {
        let faultList = res.data || [];
        this.form.fault.forEach((item) => {
          let findResult = faultList.find((f) => {
            return f.damage_title === item.damage_title;
          });
          if (findResult) {
            item.damage_id = findResult.damage_id;
          }
        });

        this.faultList = faultList;
      });
    },
    checkOrder() {
      return checkOrder(1, this.form.IMEI).then((res) => {
        this.hasYearChange = res.data !== 0;
      });
    },
    getBrand() {
      getPhoneBrand({ type: this.orderData.type }).then((res) => {
        this.brandList = res.data.brandList;
      });
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({ brand_id }).then((res) => {
        this.modelList = res.data;
      });
    },
    brandChange(data) {
      this.modelList = [];
      this.$set(this.form, "modelId", "");
      this.getModel(data.brand_id);
    },
    // 权益选择变化绑定爱思和手机后盖照片
    equityChange() {
      console.log(this.form.equity, 'form.equity');
      const hasPhoneBackPic = this.form.equity.some(item => item.eqname === '后盖');
      const hasPhoneBackPicp = this.form.equity.some(item => item.eqname === '换机');
      const hasAisiScreenShoot = this.form.equity.some(item => item.eqname === '电池' && this.orderData.type === 1);

      if (hasPhoneBackPic||hasPhoneBackPicp) {
        this.requiredSet.add("phoneBackPic");
      } else {
        this.requiredSet.delete("phoneBackPic");
      }
      if (hasAisiScreenShoot) {
        this.requiredSet.add("aisiScreenShoot");
      } else {
        this.requiredSet.delete("aisiScreenShoot");
      }
    },
    serviceChange(data) {
      // if (data === this.serviceList[2] && this.orderData.type === 1) {
      //   this.requiredSet.add("aisiScreenShoot");
      // } else {
      //   this.requiredSet.delete("aisiScreenShoot");
      // }

      // if (data === this.serviceList[1]) {
      //   this.requiredSet.add("phoneBackPic");
      //   // if (this.faultList[0] === this.form.fault && this.orderData.type === 1) {
      //   // if (!this.form.fault.some(item=>{item.id===this.faultList[0].id}) && this.orderData.type === 1) {
      //   //   this.requiredSet.add('aisiScreenShoot')
      //   // } else {
      //   //   this.requiredSet.delete('aisiScreenShoot')
      //   // }
      // } else {
      //   this.requiredSet.delete("phoneBackPic");
      // }
    },
    faultChange(data) {
      console.log(this.form.fault)
      // if (this.faultList[0] === data && this.orderData.type === 1) {
      //如果屏幕损坏不显示爱思助手截图
      if (
        this.faultList.length &&
        !data.some((item) => item.damage_id === this.faultList[0].damage_id) &&
        this.orderData.type === 1 &&
        this.form.service === this.serviceList[2]
      ) {
        this.requiredSet.add("aisiScreenShoot");
      } else {
        this.requiredSet.delete("aisiScreenShoot");
      }
      if (this.form.fault.length >= 2) {
        if (this.hasYearChange) {
          this.$myDialog({
            type: "text",
            title: "年年焕新提示",
            isShowCancel: true,
            message: `系统检测您享有年年焕新服务，是否选择更换新机？`,
            confirmButtonText: "是",
            cancelButtonText: "否",
            beforeClose: (action, done) => {
              if (action === "confirm") {
                window.location.href = window.location.href
                  .replace(
                    "brokenScreen",
                    `${this.orderData.type === 1 ? "iphone" : "android"
                    }YearChange`
                  )
                  .replace(/instype_id=\d+/, "instype_id=4");
                done();
              } else {
                setTimeout(() => {
                  this.repairDialog();
                  done();
                }, 300);

                done();
              }
            },
          });
        } else {
          this.repairDialog();
        }
      }
    },
    repairDialog() {
      this.$myDialog({
        type: "text",
        title: "维修提醒",
        message: `您当前设备状态存在多处损坏可能存在权益保障以外的维修，需由您自行承担维修费用，请您知晓！`,
        confirmButtonText: "确定",
        beforeClose: (action, done) => {
          if (action === "confirm") {
            done();
          } else {
            done();
          }
        },
      });
    },
    confirm() {
      if (!this.validateForm()) {
        return;
      }
      let { userInfo } = this.$store.state;
      let { form } = this;
      console.log();
      let additional=[]
      if(this.form.service===this.serviceList[4]){
        additional=form.additional.filter(element => element !== '')
      }
      let request;
      let params = {
        tem_img: form.phoneFrontPic,
        tem_img2: form.tem_img2,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        tem_elsie: form.aisiScreenShoot,
        tem_back: form.phoneBackPic,
        additional: additional,//新加additional参数
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service,
        bare_price: this.form.bare_price,
        claims_money:
          this.anPriceZxData && this.anPriceZxData.claims_money
            ? this.anPriceZxData.claims_money
            : "",
        remark:
          this.anPriceZxData && this.anPriceZxData.remark
            ? this.anPriceZxData.remark
            : "",
        damage_title: form.fault.map((item) => item.damage_title).join(","),
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode:form.maintainMethod,
        repair_city: form.address.city,
        gps: form.address.detail,
        son_strs: form.son_strs.map((item) => item.son_id).join(","),
      };
      if (this.type === "add") {
        params.openid = userInfo.openid;
        params.nickname = userInfo.nickname;
        params.headimg = userInfo.headimg;
        request = setOrderClaims(params);
      } else {
        params.claims_id = this.orderData.claims_id;
        request = editOrderClaims(params);
      }
      request
        .then((res) => {
          if (res && res.code) {
            this.$myDialog({
              type: "success",
              title: "提交成功，正在审核中，请稍等...",
              confirmButtonText: "确定",
              beforeClose: (action, done) => {
                if (action === "confirm") {
                  done();

                  // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                  this.$router.push("/home");
                } else {
                  done();
                }
              },
            });
          } else {
            this.$toast(res.msg || "审核申请提交失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "审核申请提交失败");
        });
    },
  },
};
</script>

<style scoped>
.mb-30 {
  margin-bottom: 30px;
}

.non-essential {
  font-size: 28px;
  margin-left: 20px;
  color: #aaa;
  font-weight: 100;
}
.imags{
  border-radius: 10px;
  width: 2.68rem;
  height: 2.68rem;
  margin-right: 0.26667rem;
}
.tabimg{
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 3.68rem;
  overflow: auto;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}
</style>
