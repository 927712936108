<template>
  <div>
    <section-title show-asterisk>损坏部件视频</section-title>
    <notice class="mb-30">注：请上传30秒内损坏部件视频或上传完好验机视频(换机)</notice>
    <video-uploader :src="form.test_video" @change="(url) => {form.test_video = url}"></video-uploader>

    <section-title>照片上传</section-title>
    <notice class="mb-30">注：维修工单（小票），维修完成后的手机跟小票放到一起拍照（手机需显示IMEI码）</notice>
    <pic-uploader @change="(url) => (form.tem_receive = url)" :src="form.tem_receive"
      :example="require('@/assets/images/examplePic/example10.png')"></pic-uploader>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="(url) => (form.tem_card = url)" :src="form.tem_card"
      :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title show-asterisk>理赔信息</section-title>
    <scan-id-card-number v-model="form.id_card" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    
    <van-cell title="姓名" :value="form.name || '--'"></van-cell>
    <van-cell title="手机号码" :value="form.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="form.imei || '--'"></van-cell>
    <select-search-cell title="手机品牌" :disabled="true" v-model="form.brand_id" placeholder="请输入查询或选择手机品牌" :options="brandList"
      @change="brandChange" label-key="brand" id-key="brand_id"></select-search-cell>
    <select-search-cell title="手机型号" :disabled="true" v-model="form.model_id" placeholder="请输入查询或选择手机型号" :options="modelList"
      label-key="model_name" id-key="model_id"></select-search-cell>
    <get-city title="维修城市" v-model="form.address"></get-city>

    <section-title show-asterisk>维修/换机</section-title>
    <field-piker
      label="服务选择"
      title="请选择服务选择"
      v-model="form.ins_service_name"
      placeholder="请选择服务选择"
      :columns="serviceList"
      @getMessage="serviceChange"
    ></field-piker>
    <van-cell-group v-if="form.ins_service_name === '维修' && faultList.length">
      <van-field v-for="item of faultList" :key="item.damage_id" v-model="item.price" type="number" :label="item.damage_title" input-align="right" placeholder="请输入价格" @change="priceResult = null" />
    </van-cell-group>

    <van-cell v-if="form.ins_service_name" title="价格">
      <template v-if="priceResult">保险理赔：¥{{priceResult.claims_money}}；补交：¥{{priceResult.money}}</template>
      <van-button v-else size="mini" type="primary" @click="getPrice">获取价格</van-button>
    </van-cell>

    <my-button class="confirm-btn" :type="isDone ? 'primary' : 'default'" :disabled="!isDone" @click="confirm">提交申请
    </my-button>
  </div>
</template>

<script>
import { getPhoneBrand, getPhoneModel, brokenEquity } from "@/api";
import {
  checkOrder,
  damageConfig3,
  editOrderClaims,
  getOrderClaimsInfo,
  getOrderInfo2,
  setOrderClaims,
  accountGear
} from "@/api/claimSettlement";
import mixin from "@/views/ClaimSettlement/apply/mixin";
import _ from 'lodash-es'

export default {
  name: "zuansu",
  mixins: [mixin],
  data() {
    let form = {
      // 页面表单参数
      name: "",
      phone: "",
      imei: "",
      brand_id: "",
      model_id: "",
      test_video: "",
      tem_receive: "",
      tem_card: "",
      id_card: "",
      ins_service_name: "",
      address: "",
      // 其他参数
      order_id: "",
      claims_id: ""
    };
    return {
      instype_id:'',//保险类型ID
      // 权益选择
      equityList: [],
      form,
      formItems: {},
      faultList: [],
      brandList: [],
      modelList: [],
      //todo
      serviceList: ["换机", "维修"],

      // 必填列表
      requiredSet: new Set(
        Object.keys(form).filter((item) => {
          return (
            [
              "test_video",
              "tem_card",
              "id_card",
              "ins_service_name",
              "address"
            ].indexOf(item) !== -1
          );
        })
      ),
      //是否已填完
      isDone: false,
      type: "add",
      hasYearChange: true,
      priceResult: null
    };
  },
  mounted() {
    let request;
    let type = this.$route.query.claims_id ? "edit" : "add";
    this.type = type;
    if (type === "add") {
      request = getOrderInfo2({
        id: this.$route.query.id,
        instype_id: this.$route.query.instype_id,
      });
    } else {
      request = getOrderClaimsInfo(this.$route.query.claims_id);
    }
    request.then((res) => {
      let data = type === "add" ? res.data : res.data.order;
      this.orderData = data;
      if (data) {
        this.form = _.pick(data, Object.keys(this.form))
        this.init();
      } else {
        this.$toast(/*res.msg||*/ "无相关订单信息");
      }
    });
  },
  methods: {
    // 获取权益选择
    getEquity() {
      brokenEquity({instype_id:this.instype_id}).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if(item.eqname==el.eqname){
                item.id=el.id
              }
            })
          })
        }
      })
    },
    init() {
      this.getBrand();
      this.getModel();
      this.getFaultList();
      this.checkOrder().finally(() => {
        this.serviceChange(this.form.service);
      });
    },
    checkOrder() {
      return checkOrder(1, this.form.imei).then((res) => {
        this.hasYearChange = res.data !== 0;
      });
    },
    getPrice() {
      const { faultList } = this
      const { ins_service_name, claims_id } = this.form
      accountGear({
        ins_service_name,
        order_id: this.orderData.order_id,
        claims_id,
        claims_money: ins_service_name === '换机' ? undefined : faultList.filter(item => item.price)
      }).then(res => {
        this.priceResult = res.data
      })
    },
    getBrand() {
      getPhoneBrand({ type: this.orderData.type }).then((res) => {
        this.brandList = res.data.brandList;
      });
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({ brand_id }).then((res) => {
        this.modelList = res.data;
      });
    },
    getFaultList() {
      damageConfig3().then((res) => {
        let faultList = res.data || [];
        const { damage_array } = this.orderData
        if (damage_array && damage_array.length) {
          damage_array.forEach(item => {
            const index = faultList.findIndex(it => it.damage_id == item.damage_id)
            if (index !== -1 ) {
              faultList[index] = item
            }
          })
        }
        this.faultList = faultList
      });
    },
    brandChange(data) {
      this.modelList = [];
      this.$set(this.form, "modelId", "");
      this.getModel(data.brand_id);
    },
    serviceChange(data) {
      this.priceResult = null
    },
    confirm() {
      if (!this.validateForm()) {
        return;
      }
      let { userInfo } = this.$store.state;
      let { form, faultList, priceResult } = this;
      if (!priceResult) {
        this.$toast("请获取价格！");
        return
      }
      let request;
      let params = {
        ...form,
        ...priceResult,
        repair_city: form.address.city,
        gps: form.address.detail,
        address: undefined
      };
      params.damage_title = params.ins_service_name === '换机' ? undefined : faultList.filter(item => item.price)
      if (this.type === "add") {
        params.openid = userInfo.openid;
        params.nickname = userInfo.nickname;
        params.headimg = userInfo.headimg;
        request = setOrderClaims(params);
      } else {
        request = editOrderClaims(params);
      }
      request
        .then((res) => {
          if (res && res.code) {
            this.$myDialog({
              type: "success",
              title: "提交成功，正在审核中，请稍等...",
              confirmButtonText: "确定",
              beforeClose: (action, done) => {
                if (action === "confirm") {
                  done();

                  // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                  this.$router.push("/home");
                } else {
                  done();
                }
              },
            });
          } else {
            this.$toast(res.msg || "审核申请提交失败");
          }
        })
        .catch((err) => {
          this.$toast((err && err.msg) || "审核申请提交失败");
        });
    },
  },
};
</script>

<style scoped>
.mb-30 {
  margin-bottom: 30px;
}

.non-essential {
  font-size: 28px;
  margin-left: 20px;
  color: #aaa;
  font-weight: 100;
}

.submit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}
</style>
