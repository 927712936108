<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30">注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice>
    <pic-uploader @change="url=>form.phoneFrontPic=url" :src="form.phoneFrontPic"
                  :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="url=>form.idCardPic=url" :src="form.idCardPic"
                  :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title :show-asterisk="requiredSet.has('aisiScreenShoot')">爱思助手/沙漏验机截图<span class="non-essential" v-if="!requiredSet.has('aisiScreenShoot')">(非必填)</span></section-title>
    <notice class="mb-30">注：请上传爱思助手/沙漏验机点击【查看验机报告】后截图</notice>
    <pic-uploader @change="url=>form.aisiScreenShoot=url" :src="form.aisiScreenShoot"
                  :example="require('@/assets/images/examplePic/example8.png')"></pic-uploader>

    <section-title>接件单正面照<span class="non-essential">(非必填)</span></section-title>
    <notice class="mb-30">注：上传接件单正面照</notice>
    <pic-uploader @change="url=>form.orderFrontPic=url" :src="form.orderFrontPic"
                  :example="require('@/assets/images/examplePic/example7.jpg')"></pic-uploader>

    <section-title show-asterisk>理赔信息</section-title>
<!--    <cell-input title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

    <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname"></multiple-choice>
    <van-cell title="姓名" :value="form.name||'--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard||'--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone||'--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI||'--'"></van-cell>

    <select-search-cell title="手机型号" :disabled="true" v-model="form.modelId" placeholder="请输入查询或选择手机型号" :options="modelList"
                        label-key="model_name"
                        id-key="model_id"></select-search-cell>

    <multiple-choice title="手机故障"
                     placeholder="请选择手机故障"
                     v-model="form.fault"
                     :options="faultList"
                     id-key="damage_id"
                     name-key="damage_title"
                     @change="faultChange"></multiple-choice>

    <field-piker label="理赔服务" title="请选择理赔服务" v-model="form.service" placeholder="请选择理赔服务" :columns="serviceList"
                 @getMessage="serviceChange"></field-piker>

    <field-piker v-if="form.service" label="新设备选择" title="请选择新设备更换方式" v-model="form.upgrade" placeholder="请选择新设备更换方式"
                 :columns="upgradeList" @getMessage="upgradeChange"></field-piker>

    <template v-if="form.upgrade===upgradeList[1]">
      <select-search-cell title="新设备型号" v-model="form.newModelId" placeholder="请输入查询或选择新设备型号" :options="modelList"
                          label-key="model_name"
                          id-key="model_id" @change="modelChange"></select-search-cell>

      <field-piker label="新设备内存" title="请选择新设备内存" v-model="form.newRAM" placeholder="请选择新设备内存"
                   :columns="RAMList"  @getMessage="RAMChange"></field-piker>
    </template>

      <van-cell title="原设备官网价" v-if="form.upgrade">
        <template #default>
          <input type="text" class="money-input" v-model="originalPrice"
                 placeholder="原设备官网价" disabled>
        </template>
      </van-cell>
      <van-cell title="POS下账金额" v-if="form.upgrade">
        <template #default>
          <input type="text" class="money-input" v-model="posMoney"
                 placeholder="POS下账金额" disabled>
        </template>
      </van-cell>
      <van-cell title="用户补交金额"  v-if="form.upgrade">
        <template #default>
          <input type="text" class="money-input" v-model="makeUpMoney"
                 placeholder="用户补交金额" disabled>
        </template>
      </van-cell>
      <van-cell title="用户升级补差" v-if="form.upgrade===upgradeList[1]">
        <template #default>
          <input type="text" class="money-input" v-model="upgradeDiffMoney"
                 placeholder="用户升级补差" disabled>
        </template>
      </van-cell>
      <van-cell title="保险赔付金额">
        <template #default>
          <input type="text" class="money-input" v-model="insMoney"
                 placeholder="保险赔付金额" disabled>
        </template>
      </van-cell>

    <get-city title="维修城市" v-model="form.address"></get-city>


    <my-button class="confirm-btn" :type="isDone?'primary':'default'" :disabled="!isDone"
               @click="confirm">提交申请
    </my-button>

  </div>
</template>

<script>
import {
  applePriceNnhx,
  damageConfig2,
  editOrderClaims,
  getOrderInfo2,
  nnhxService,
  setOrderClaims
} from "@/api/claimSettlement";
import {getPhoneBrand, getPhoneModel, brokenEquity} from "@/api";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "iphoneYearChange",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      phoneFrontPic: '',
      idCardPic: '',
      aisiScreenShoot: '',
      orderFrontPic: '',
      name: '',
      idCard: '',
      phone: '',
      IMEI: '',
      modelId: '',
      fault: [],
      address: {},
      upgrade: '',
      newModelId: '',
      newRAM: ''
    }
    return {
      instype_id:'',//保险类型ID
      // 权益选择
      equityList: [],
      form,
      orderData: null,
      serviceList: [],
      faultList: [],
      modelList: [],
      upgradeList: ['同代同型换新机', '迭代升级换新机'],
      RAMList: [],

      originalPrice: '',
      posMoney: '',
      makeUpMoney: '',
      upgradeDiffMoney: '',
      insMoney: '',

      requiredSet: new Set(Object.keys(form).filter(key => {
        return ['orderFrontPic','upgrade', 'newRAM', 'newModelId','address'].indexOf(key) === -1
      })),
      isDone: false
    }
  },
  mounted() {
     // instype_id 保险类型ID
     this.instype_id=this.$route.query.instype_id
    getOrderInfo2({
      id: this.$route.query.id,
      instype_id: 4
    }).then(res => {
      if (res.data.type === 2) {
        alert('请选择安卓保值焕新服务理赔')
        this.$router.push('/ClaimSettlement/selectType')

        return
      }
      let data = res.data
      this.orderData = data
      if (data) {
        this.form.name = data.name
        this.form.idCard = data.id_card
        this.form.phone = data.phone
        this.form.IMEI = data.imei
        this.form.brandId = data.brand_id
        this.form.modelId = data.model_id
        this.form.phoneFrontPic = data.img || ''
        this.form.phoneBackPic = data.back || ''
        this.form.idCardPic = data.card || ''
        this.form.orderFrontPic = data.receive || ''
        this.form.aisiScreenShoot = data.elsie || ''
        // this.form.service = data.ins_service_name||''
        this.form.fault = data.damage_title || []
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
        // this.equityChange(this.form.equity)
        this.init()
        // 获取权益选择
        this.getEquity()
      } else {
        this.$toast(/*res.msg||*/'无相关订单信息')
      }
    });
  },
  methods: {
     // 获取权益选择
     getEquity() {
      console.log(this.instype_id,'this.instype_id');
      brokenEquity({instype_id:this.instype_id}).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if(item.eqname==el.eqname){
                item.id=el.id
              }
            })
          })
        }
        console.log(this.equityList, 'qqqqqqqqq');
      })
    },
    init() {
      this.getModelList(1)
      this.getServiceList()
      this.getFaultList()
      this.getRAMList()
    },
    getServiceList() {
      nnhxService(1).then(res => {
        this.serviceList = res.data
      })
    },
    getFaultList() {
      damageConfig2().then(res => {
        this.faultList = res.data
      })
    },
    getModelList(brand_id) {
      getPhoneModel({brand_id}).then(res => {
        this.modelList = res.data
      })
    },
    getRAMList() {
      getPhoneBrand({type: 1}).then(res => {
        let ramList = res.data.ramList || []
        this.RAMList = ramList.map(i => (i.ram_size))
      })
    },
    getPrice() {
      let {form}=this
      if (!form.service||!form.upgrade||!form.fault.length) {
        return
      }
      applePriceNnhx({
        order_id:this.orderData.order_id,
        ins_service_name:form.service,
        is_up:form.upgrade,
        damage_title:form.fault.map(item=>item.damage_title).join(','),
        new_model:form.newModelId,
        new_ram:form.newRAM
      }).then(res=>{
        this.originalPrice=res.data.bare_price
        this.posMoney=res.data.new_price
        this.makeUpMoney=res.data.money
        this.upgradeDiffMoney=res.data.diff_money
        this.insMoney=res.data.claims_money
      }).catch(()=>{
        this.originalPrice=''
        this.posMoney=''
        this.makeUpMoney=''
        this.upgradeDiffMoney=''
        this.insMoney=''
      })
    },
    faultChange(e) {
      if (e.some(item => {
        return item.damage_title === "内屏损坏"
      })) {
        this.requiredSet.delete('aisiScreenShoot');
      } else {
        this.requiredSet.add('aisiScreenShoot');
      }
      this.getPrice();
    },
    modelChange() {
      this.getPrice()
    },
    RAMChange() {
      this.getPrice()
    },
    serviceChange() {
      this.requiredSet.add('upgrade')
      this.getPrice()
    },
    upgradeChange(data) {
      if (data === this.upgradeList[1]) {
        this.requiredSet.add('newModelId');
        this.requiredSet.add('newRAM');
      } else {
        this.requiredSet.delete('newModelId');
        this.requiredSet.delete('newRAM');
      }
      this.getPrice()
    },
    confirm() {
      if (!this.validateForm()) {
        return
      }
      let {userInfo} = this.$store.state;
      let {form} = this
      let params = {
        openid: userInfo.openid,
        nickname: userInfo.nickname,
        headimg: userInfo.headimg,
        tem_img: form.phoneFrontPic,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        tem_elsie: form.aisiScreenShoot,
        tem_back: form.phoneBackPic,
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service,
        damage_title: form.fault.map(item=>item.damage_title).join(','),
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode: form.maintainMethod,
        repair_city: form.address.city,
        gps: form.address.detail,

        is_up: form.upgrade,
        // intact: form.fault,
        new_model: form.newModelId,
        new_ram: form.newRAM,
        new_price: this.posMoney,
        bare_price: this.originalPrice,
        money: this.makeUpMoney,
        claims_money: this.insMoney,
        diff_money: this.upgradeDiffMoney
      }

      setOrderClaims(params).then(res => {
        if (res && res.code) {
          this.$myDialog({
            type: 'success',
            title: '提交成功，正在审核中，请稍等...',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                done()

                // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                this.$router.push('/home');
              } else {
                done()
              }
            }
          });
        } else {
          this.$toast(res.msg || '审核申请提交失败');
        }
      }).catch(err => {
        this.$toast(err && err.msg || '审核申请提交失败');
      })
    }
  }
}
</script>

<style scoped>
.money-input {
  text-align: right;
  background-color: #fff;
  color: #999;
}
</style>
