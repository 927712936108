<template>
  <div>
    <section-title show-asterisk>设备正面照片</section-title>
    <notice class="mb-30">注：请在拨号界面输入*#06#串码或序列号界面拍照 ，延保或电池出险，均需如此操作</notice>
    <pic-uploader @change="url => form.phoneFrontPic = url" :src="form.phoneFrontPic"
      :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <section-title show-asterisk>身份证正面照</section-title>
    <notice class="mb-30">注：请上传客户身份证照片</notice>
    <pic-uploader @change="url => form.idCardPic = url" :src="form.idCardPic"
      :example="require('@/assets/images/examplePic/example5.jpg')"></pic-uploader>

    <section-title>接件单正面照<span class="non-essential">(非必填)</span></section-title>
    <notice class="mb-30">注：上传接件单正面照</notice>
    <pic-uploader @change="url => form.orderFrontPic = url" :src="form.orderFrontPic"
      :example="require('@/assets/images/examplePic/example7.jpg')"></pic-uploader>

    <section-title show-asterisk>理赔信息</section-title>
    <!--
    <cell-input title="姓名" v-model="form.name" placeholder="请填写姓名"></cell-input>

    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>

    <cell-input title="手机号码" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <scan-code v-model="form.IMEI" title="IMEI号" placeholder="请输入手机IMEI号或扫码"></scan-code>
-->
    <multiple-choice title="权益选择" placeholder="请选择权益" v-model="form.equity" :options="equityList" id-key="id"
      name-key="eqname"></multiple-choice>
    <van-cell title="姓名" :value="form.name || '--'"></van-cell>
    <!-- <van-cell title="身份证号" :value="form.idCard || '--'"></van-cell> -->
    <scan-id-card-number v-model="form.idCard" title="身份证号" placeholder="请输入或扫描身份证号"></scan-id-card-number>
    <van-cell title="手机号码" :value="form.phone || '--'"></van-cell>
    <van-cell title="IMEI号" :value="form.IMEI || '--'"></van-cell>

    <select-search-cell title="手机品牌" v-model="form.brandId" placeholder="请输入查询或选择手机品牌" :options="brandList" :disabled="true"
      @change="brandChange" label-key="brand" id-key="brand_id"></select-search-cell>

    <select-search-cell title="手机型号" v-model="form.modelId" placeholder="请输入查询或选择手机型号" :options="modelList" :disabled="true"
      label-key="model_name" id-key="model_id"></select-search-cell>

    <field-piker label="手机规格" title="请选择手机规格" v-model="form.RAM" :disabled="true" placeholder="请选择手机规格" :columns="RAMList"></field-piker>

    <field-piker label="网络制式" title="请选择网络制式" v-model="form.net" :disabled="true" placeholder="请选择网络制式" :columns="netList"></field-piker>

    <multiple-choice title="损坏情况" placeholder="请选择损坏情况" v-model="form.fault" :options="faultList" id-key="damage_id"
      name-key="damage_title"></multiple-choice>

    <field-piker label="理赔服务" title="请选择理赔服务" v-model="form.service" placeholder="请选择理赔服务" :columns="serviceList"
      @getMessage="serviceChange"></field-piker>

    <template v-if="form.service">
      <van-cell title="原设备购机价">
        <template #default>
          <input type="text" class="money-input" v-model="originalPrice" placeholder="原设备购机价" disabled>
        </template>
      </van-cell>

      <!--          <van-cell title="新设备购机价">-->
      <!--            <template #default>-->
      <!--              <input type="text" class="money-input" v-model="form.newPrice"-->
      <!--                     placeholder="新设备购机价" disabled>-->
      <!--            </template>-->
      <!--          </van-cell>-->
      <money title="新设备购机价" placeholder="新设备购机价" v-model="form.newPrice" color="#333" @change="newPriceChange"></money>

      <van-cell title="用户补交金额">
        <template #default>
          <input type="text" class="money-input" v-model="makeUpMoney" placeholder="用户补交金额" disabled>
        </template>
      </van-cell>

      <van-cell title="服务履约金额">
        <template #default>
          <input type="text" class="money-input" v-model="serviceMoney" placeholder="保险赔付金额" disabled>
        </template>
      </van-cell>
    </template>

    <get-city title="维修城市" v-model="form.address"></get-city>


    <my-button class="confirm-btn" :type="isDone ? 'primary' : 'default'" :disabled="!isDone" @click="confirm">提交申请
    </my-button>

  </div>
</template>

<script>
import {
  anPriceNnhx,
  damageConfig2,
  editOrderClaims,
  getOrderInfo2,
  nnhxService,
  setOrderClaims
} from "@/api/claimSettlement";
import { getPhoneBrand, getPhoneModel, brokenEquity  } from "@/api";
import mixin from "@/views/ClaimSettlement/apply/mixin";

export default {
  name: "androidYearChange",
  mixins: [mixin],
  data() {
    let form = {
      equity: [],//权益
      phoneFrontPic: '',
      idCardPic: '',
      orderFrontPic: '',
      name: '',
      idCard: '',
      phone: '',
      IMEI: '',
      modelId: '',
      fault: [],
      address: {},
      net: '',
      RAM: '',
      service: '',
      newPrice: ''
    }
    return {
      instype_id:'',//保险类型ID
      // 权益选择
      equityList: [],
      form,
      orderData: null,
      brandList: [],
      serviceList: [],
      faultList: [],
      modelList: [],
      RAMList: [],
      netList: [],

      originalPrice: '',
      // newPrice: '',
      makeUpMoney: '',
      serviceMoney: '',

      requiredSet: new Set(Object.keys(form).filter(key => {
        return ['orderFrontPic', 'address'].indexOf(key) === -1
      })),
      isDone: false
    }
  },
  mounted() {
      // instype_id 保险类型ID
      this.instype_id=this.$route.query.instype_id
    getOrderInfo2({
      id: this.$route.query.id,
      instype_id: 4
    }).then(res => {
      let data = res.data
      if (data.type === 1) {
        alert('请选择苹果年年焕新服务理赔')
        this.$router.push('/ClaimSettlement/selectType')

        return
      }
      this.orderData = data;
      if (data) {
        this.form.name = data.name
        this.form.idCard = data.id_card
        this.form.phone = data.phone
        this.form.IMEI = data.imei
        this.form.brandId = data.brand_id
        this.form.modelId = data.model_id
        this.form.phoneFrontPic = data.img || ''
        this.form.idCardPic = data.card || ''
        this.form.orderFrontPic = data.receive || ''
        this.form.net = data.net_name || ''
        this.form.RAM = data.ram_size || ''
        // this.form.service = data.ins_service_name||''
        this.form.fault = data.damage_title || []
        this.originalPrice = data.bare_price || ''
        this.form.equity = (data.equity &&
          data.equity.split(",").map((item) => ({
            eqname: item,
          }))) ||
          [];
        this.init()
          // 获取权益选择
          this.getEquity()
      } else {
        this.$toast(/*res.msg||*/'无相关订单信息')
      }
    });
  },
  methods: {
     // 获取权益选择
     getEquity() {
      console.log(this.instype_id,'this.instype_id');
      brokenEquity({instype_id:this.instype_id}).then(res => {
        this.equityList = res.data
        if (this.form.equity.length) {//编辑时将返回的equity回显到选项列表
          this.equityList.forEach(el => {
            this.form.equity.forEach(item => {
              if(item.eqname==el.eqname){
                item.id=el.id
              }
            })
          })
        }
        console.log(this.equityList, 'qqqqqqqqq');
      })
    },
    init() {
      this.getBrand()
      this.getModel()
      this.getServiceList()
      this.getFaultList()
    },
    getBrand() {
      getPhoneBrand({ type: 2 }).then(res => {
        this.brandList = res.data.brandList
        let ramList = res.data.ramList || []
        this.RAMList = ramList.map(i => (i.ram_size))

        let netList = res.data.netList || []
        this.netList = netList.map(i => (i.net_name))
      })
    },
    getModel(brand_id = this.orderData.brand_id) {
      getPhoneModel({ brand_id }).then(res => {
        this.modelList = res.data
      })
    },
    getServiceList() {
      nnhxService(2).then(res => {
        this.serviceList = res.data
      })
    },
    getFaultList() {
      damageConfig2().then(res => {
        this.faultList = res.data
      })
    },
    getModelList(brand_id) {
      getPhoneModel({ brand_id }).then(res => {
        this.modelList = res.data
      })
    },
    brandChange(data) {
      this.modelList = []
      this.$set(this.form, 'modelId', '')
      this.getModel(data.brand_id)
    },
    faultChange() {

    },
    serviceChange() {
      this.requiredSet.add('upgrade')
      if (this.form.newPrice) {
        this.getPrice()
      }
    },
    newPriceChange() {
      this.getPrice()
    },
    getPrice() {
      anPriceNnhx({
        order_id: this.orderData.order_id,
        new_price: this.form.newPrice,
        ins_service_name: this.form.service
      }).then(res => {
        this.serviceMoney = res.data.claims_money
        this.makeUpMoney = res.data.money
      })
    },
    confirm() {
      if (!this.validateForm()) {
        return
      }
      let { userInfo } = this.$store.state;
      let { form } = this

      let params = {
        openid: userInfo.openid,
        nickname: userInfo.nickname,
        headimg: userInfo.headimg,
        tem_img: form.phoneFrontPic,
        tem_receive: form.orderFrontPic,
        tem_card: form.idCardPic,
        // tem_back: form.phoneBackPic,
        // tem_elsie: form.aisiScreenShoot,
        order_id: this.orderData.order_id,
        name: form.name,
        phone: form.phone,
        id_card: form.idCard,
        imei: form.IMEI,
        brand_id: form.brandId,
        model_id: form.modelId,
        ins_service_name: form.service,
        damage_title: form.fault.map(item => item.damage_title).join(','),
        equity: form.equity.map((item) => item.eqname).join(","),
        // repair_mode: form.maintainMethod,
        repair_city: form.address.city,
        gps: form.address.detail,
        ram_size: form.RAM,
        net_name: form.net,

        // is_up:form.upgradeType,
        // intact:form.fault,
        // new_model:form.upgradeModel.model_id,
        // new_ram:form.upgradeRAM,
        new_price: form.newPrice,
        bare_price: this.orderData.bare_price,
        money: this.makeUpMoney,
        claims_money: this.serviceMoney
      }
      setOrderClaims(params).then(res => {
        if (res && res.code) {
          this.$myDialog({
            type: 'success',
            title: '提交成功，正在审核中，请稍等...',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                done()

                // this.$router.push('/ClaimSettlement/detail/'+res.data.claims_id);
                this.$router.push('/home');

              } else {
                done()
              }
            }
          });
        } else {
          this.$toast(res.msg || '审核申请提交失败');
        }
      }).catch(err => {
        this.$toast(err && err.msg || '审核申请提交失败');
      });
    }
  }
}
</script>

<style scoped>
.money-input {
  text-align: right;
  background-color: #fff;
  color: #999;
}
</style>
